import React, { useState } from 'react'

import { Divider, Flex, Heading, VStack } from '@rhythm/components'

import {
  TransmissionAlertsCards,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { useGetICDDiagnosisCodes } from '../../../../../features/transmissionReports/api/getICDDiagnosisCodes'
import { ClinicianInterpretationCards } from '../../../../../features/transmissionReports/components/TransmissionReportClinicalInterpretation'
import {
  TransmissionReportDto,
  VtLeadVendor,
  VtVendor,
} from '../../../../../lib/api'
import { REASONS_FOR_MONITORING_OPTIONS } from '../../../../../utils/constants/transmissionReports'
import { PractitionerOption } from '../../TransmissionReportPage'
import BillingCard from '../BillingCard'
import CountersValuesCard from '../CountersValuesCard'
import DeviceBatteryCard from '../DeviceBatteryCard'
import DeviceDemographicsCard from '../DeviceDemographicsCard'
import DeviceMonitoredValuesCard from '../DeviceMonitoredValuesCard'
import PatientDemographicsCard from '../PatientDemographicsCard'
import SignOffCard from '../SignOffCard'
import TransmissionNotesCard from '../TransmissionNotesCard'
import TransmissionSummaryCard from '../TransmissionSummaryCard'

const TransmissionReportDetails = ({
  vendors,
  leadVendors,
  trReport,
  practitionerOptions,
}: {
  vendors?: VtVendor[]
  leadVendors?: VtLeadVendor[]
  trReport?: TransmissionReportDto
  practitionerOptions?: PractitionerOption[]
}): React.ReactElement => {
  const { transmissionReport, setCurrentTransmission } =
    useTransmissionReportContext()

  if (trReport) {
    setCurrentTransmission(trReport)
  }
  const ehrIntegration: boolean =
    transmissionReport?.account?.ehrIntegration ?? false
  const isVisibleComment =
    (transmissionReport?.account?.sentToEhr ?? 'secondSign') === 'secondSign'

  const deviceType = transmissionReport?.device?.deviceType
  let report = false
  let iLRReport = true
  let mbStyle = '5x1'
  const check = 'ILR'
  if (deviceType === check) {
    mbStyle = '1x1'
    report = true
    iLRReport = false
  }

  const [callDelete, setCallDelete] = useState(false)

  const handleNoteDelete = (noteId: string) => {
    setCallDelete(prevcallDelete => !prevcallDelete)
  }

  const isRemote = transmissionReport?.transmissionType === 'remote'

  const isExistMonitorReason = REASONS_FOR_MONITORING_OPTIONS.find(
    e => e.value === transmissionReport?.patient?.monitorReason,
  )

  if (
    transmissionReport &&
    transmissionReport.patient &&
    !isExistMonitorReason
  ) {
    transmissionReport.patient.monitorReason = ''
  }
  return (
    <>
      <TransmissionSummaryCard transmissionReport={transmissionReport} />

      <Flex flexDirection="column" mt="5xl" mb="3xl">
        <Heading variant="h6" mb="xl">
          Background
        </Heading>
        <VStack direction={{ base: 'column', md: 'row' }} spacing="xl">
          <PatientDemographicsCard
            key={transmissionReport?.patient?.id}
            transmissionReport={transmissionReport}
            ReasonForMonitorILR={!iLRReport}
            practitionerOptions={practitionerOptions}
            ehrIntegration={ehrIntegration}
          />
          <DeviceDemographicsCard
            key={`device-demographics-${transmissionReport?.id}`}
            transmissionReport={transmissionReport}
            vendors={vendors}
            leadVendors={leadVendors}
          />
        </VStack>
      </Flex>
      <Divider />
      <VStack mb={isRemote ? '5xl' : ''} spacing="xl" mt="3xl">
        <TransmissionAlertsCards callDelete={callDelete} />
      </VStack>
      <VStack mb="3xl" spacing="xl" mt="1xl" hidden={iLRReport}>
        <TransmissionNotesCard onDeleteNote={handleNoteDelete} />
        <Flex flexDirection="row" width="100%" hidden={!isVisibleComment}>
          <ClinicianInterpretationCards
            transmissionReport={transmissionReport}
          />
        </Flex>
      </VStack>
      {isRemote && (
        <VStack hidden={report}>
          <DeviceMonitoredValuesCard />
        </VStack>
      )}
      {isRemote && (
        <VStack hidden={iLRReport}>
          <CountersValuesCard />
        </VStack>
      )}
      {isRemote && (
        <VStack mb={mbStyle} spacing="xl" mt="3xl">
          <DeviceBatteryCard />
        </VStack>
      )}
      <VStack hidden={report} spacing="xl" mt="3xl">
        Transmission Notes Card
        <TransmissionNotesCard onDeleteNote={handleNoteDelete} />
        <Flex flexDirection="row" width="100%" hidden={!isVisibleComment}>
          <ClinicianInterpretationCards
            transmissionReport={transmissionReport}
          />
        </Flex>
      </VStack>

      <VStack spacing="xl" mt={isRemote ? '3xl' : 'xl'}>
        {isRemote && <BillingCard />}
      </VStack>
      <VStack mb="3xl" spacing="xl" mt={'3xl'}>
        <SignOffCard transmissionReport={transmissionReport} />
      </VStack>
    </>
  )
}

export default TransmissionReportDetails
