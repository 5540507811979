import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiReportTemplateControllerRemoveTemplateRequest,
  queryClient,
} from '../../../lib/api'

export const useRemoveReportTemplate = (toast: any) => {
  const Api = useApiContext()
  return useMutation(
    (
      reportTemplateRemove: DefaultApiReportTemplateControllerRemoveTemplateRequest,
    ) => Api.reportTemplateControllerRemoveTemplate(reportTemplateRemove),
    {
      onSuccess: ({ data }) => {
        queryClient
          .invalidateQueries('transmissionReport')
          .then(() => {
            toast(data.template)
          })
          .catch(() => {})
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
