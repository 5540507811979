import { EMPTY_COUNTERS_PLACEHOLDER } from './constants'

type Options = {
  placeholder?: string
  separator?: string
}

const displayCounterValue = (
  value: string | null | undefined,
  options: Options | undefined = {
    placeholder: EMPTY_COUNTERS_PLACEHOLDER,
    separator: ',',
  },
) => {
  const { placeholder, separator } = options
  if (
    typeof value === 'string' &&
    value?.length &&
    separator &&
    value.includes(separator)
  ) {
    return value.split(separator, 1)[0]
  }
  return value ? value : placeholder
}

export default displayCounterValue
