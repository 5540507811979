import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Divider,
  Error,
  HStack,
  Icon,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from '@rhythm/components'
import { useDropzone } from 'react-dropzone'

import { useApiContext } from '../../../../../context/ApiContext'
import { queryClient } from '../../../../../lib/api'
import { Patient } from '../../../../../types'
import FilesTable from '../ImportReportsModal/FilesTable'

export interface AddReportProps {
  onClose: (arg?: boolean | undefined) => void
  patient?: Patient | undefined
  onContinue: (arg?: boolean | undefined) => void
}

const SPECIAL_CHAR = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/) // eslint-disable-line

const checkFileName = (files: any[]) => {
  return files.filter(e => !SPECIAL_CHAR.test(e.name.split('.pdf')[0]))
}

const checkFileNameWithSpecialChar = (files: any[]) => {
  const filesss = files.filter(e => SPECIAL_CHAR.test(e.name.split('.pdf')[0]))
  const errorfil = filesss.map(e => {
    return { name: e.name, status: false }
  })
  return errorfil
}
export const AddReport = ({ patient, onClose, onContinue }: AddReportProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [reportType, setReportType] = useState('')
  const [device, setDevice] = useState<any>('')
  const [filesLength, setFilesLength] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorFiles, setErrorFiles] = useState<any[]>([])

  const Api = useApiContext()

  const options = patient
    ? patient.matchDevices
        ?.filter(e => e.modelName)
        .map(e => {
          return { label: e.modelName, value: e.modelName, id: e.id }
        })
    : []

  useEffect(() => {
    const valueOptions = patient
      ? patient.matchDevices
          ?.filter(e => e.modelName)
          .map(e => {
            return { label: e.modelName, value: e.modelName, id: e.id }
          })
      : []
    if (valueOptions?.length === 1 && valueOptions[0]) {
      setDevice(valueOptions[0])
    }
  }, [patient])

  const getUrl = (fileName: string): any => {
    return Api.transmissionReportsControllerAddInClinicPdfToTempBucket({
      patientId: patient?.id ? patient?.id.toString() : '',
      fileName,
    })
  }
  const onFileDrop = async (filesToUpload: File[]) => {
    try {
      for (let i = 0; i < filesToUpload.length; i++) {
        try {
          const { data } = await getUrl(filesToUpload[i].name)
          const result = await fetch(data.preSignedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/pdf',
              filename: filesToUpload[i].name,
            },
            body: filesToUpload[i],
          })
          const file = uploadedFiles.find(
            e => e.name === filesToUpload[i].name && e.status === null,
          )
          if (file) {
            console.log(' stt data ', file)
            if (data.preSignedUrl.toLowerCase().includes('failed')) {
              file.status = false
            } else {
              file.status = result.status === 200
            }
            setUploadedFiles([...uploadedFiles])
          }
        } catch (err) {
          console.log('Inclinic/Manual upload reports failed', err)
          const file = uploadedFiles.find(
            e => e.name === filesToUpload[i].name && e.status === null,
          )
          if (file) {
            file.status = false
            setUploadedFiles([...uploadedFiles])
          }
        }
      }
    } catch (error) {
      console.log('Inclinic/Manual upload reports failed')
    } finally {
      setIsUploading(false)
    }
  }

  const onDrop = (files: File[]) => {
    const filesLength = uploadedFiles.length + files.length - 1
    if (filesLength >= 10) {
      setFilesLength(true)
      return
    }
    setFilesLength(false)
    const filesToUpload = files.map(e => {
      return { name: e.name, status: null, specialChar: false }
    })
    setIsUploading(true)
    errorFiles.push(...checkFileNameWithSpecialChar(files))
    setErrorFiles([...errorFiles])
    uploadedFiles.push(...filesToUpload)

    for (let t = 0; t < uploadedFiles.length; t++) {
      for (let i = 0; i < errorFiles.length; i++) {
        if (errorFiles[i].name === uploadedFiles[t].name) {
          uploadedFiles[t].status = false
          uploadedFiles[t].specialChar = true
        }
      }
    }

    setUploadedFiles([...uploadedFiles])
    setTimeout(() => {
      onFileDrop(checkFileName(files))
    }, 2000)
  }
  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop,
    accept: '.pdf',
    disabled: isUploading,
  })
  const isSubmitDisable =
    reportType !== '' &&
    device !== '' &&
    uploadedFiles.filter(e => e.status).length > 0 &&
    uploadedFiles.every(e => e.status === true)

  const onSubmit = async () => {
    setIsLoading(true)
    const fileNames = uploadedFiles.filter(e => e.status).map(e => e.name)
    const requestBody: any = {
      manualORInclinicTransmissionReportRequestDto: {
        patientId: patient?.id ? patient?.id.toString() : '',
        reportType: reportType,
        files: fileNames,
        deviceId: device.id,
      },
    }
    try {
      const response =
        await Api.transmissionReportsControllerSubmitManualAndInclinicReports(
          requestBody,
        )
      const failedUploadFiles = response?.data?.fileStatus?.filter(
        e => e.status === 'failed',
      )
      if (failedUploadFiles && failedUploadFiles.length) {
        setErrorFiles(failedUploadFiles.map(e => e.fileName))
        setIsLoading(false)
      } else {
        onContinue(true)
      }
      queryClient.invalidateQueries('patient')
    } catch (error) {
      console.log('Save Report Failed:', error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onDeleteErrorFile = (index: number) => {
    console.log('index ', uploadedFiles)
    const newUploadedFiles = Object.assign([], uploadedFiles)
    newUploadedFiles.splice(index, 1)
    setUploadedFiles(newUploadedFiles)
  }
  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <>
          <Button
            style={{
              marginRight: '16px',
              background: '#455468',
              borderRadius: '5px',
            }}
            onClick={() => onClose(true)}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!isSubmitDisable}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </>
      }
    >
      <HStack>
        <Box>
          <div style={{ display: 'flex' }}>
            <div>
              <div
                style={{
                  height: '60px',
                  width: '60px',
                  background: '#EFF2F6',
                  border: '1px solid #EFF2F6',
                  borderRadius: '12px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '16px',
                    left: '16px',
                    width: '26px',
                    color: '#0D6DAD',
                  }}
                >
                  <Icon icon="add" boxSize="s" />
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '7px', paddingLeft: '16px' }}>
              <Stack>
                <label
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    marginBottom: '5px',
                  }}
                >
                  {patient?.givenName} {patient?.familyName}
                </label>
              </Stack>
              <Stack>
                <label
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    marginBottom: '30px',
                  }}
                >
                  Add Reports
                </label>
              </Stack>
            </div>
          </div>
        </Box>
      </HStack>
      <Stack style={{ marginTop: '25px', marginBottom: '8px' }}>
        <Text
          variant="secondary"
          fontSize="11px"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          REPORT TYPE &nbsp;
          <Text variant="secondary" fontSize="11px">
            (required)
          </Text>
        </Text>
        <RadioGroup
          onChange={value => {
            setReportType(value)
          }}
        >
          <HStack spacing="md" style={{ marginRight: '24px' }}>
            <Radio value="in-clinic" spacing="md">
              In-clinic Report
            </Radio>
            <span style={{ marginRight: '24px' }} />
            <Radio value="manual" spacing="md">
              Manual Report
            </Radio>
          </HStack>
        </RadioGroup>
      </Stack>
      <Stack style={{ marginTop: '25px', marginBottom: '8px' }}>
        <Text
          variant="secondary"
          fontSize="11px"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          DEVICE &nbsp;
          <Text variant="secondary" fontSize="11px">
            (required)
          </Text>
        </Text>
        <div style={{ width: '100%' }}>
          <Select
            options={options}
            onChange={(selection: any) => {
              setDevice(selection)
            }}
            value={device}
            placeholder="Select"
            noSeparator
          />
        </div>
      </Stack>
      <Stack style={{ marginTop: '20px', marginBottom: '0px' }}>
        <Center
          {...getRootProps()}
          height="200px"
          marginBottom="30px"
          borderColor="neutral.600"
          borderRadius="2px"
          borderStyle="dashed"
          borderWidth="1px"
        >
          <input {...getInputProps()} disabled={isUploading} />
          <VStack spacing="2xl">
            <Text variant="secondary">
              Drag and drop directly to this space or
            </Text>
            <Button
              disabled={isUploading}
              variant="secondaryLight"
              onClick={open}
            >
              Select files
            </Button>
          </VStack>
        </Center>
        {filesLength && (
          <Stack style={{ marginTop: '-15px' }}>
            <Error message="Max 10 files allowed" color="red" />
          </Stack>
        )}
      </Stack>
      {!!uploadedFiles.length && (
        <Stack>
          <Text
            variant="secondary"
            fontSize="11px"
            fontWeight={900}
            lineHeight="12px"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            REPORT TITLE
          </Text>
          <Divider style={{ marginBottom: '-7px' }} />
          <Box minHeight="40px" wordBreak="break-word">
            <FilesTable
              files={uploadedFiles}
              showTitle={false}
              onDeleteErrorFile={onDeleteErrorFile}
              isUploading={isUploading}
            />
          </Box>
        </Stack>
      )}
    </Modal>
  )
}
