import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  DefaultApiAnalyticsControllerSignatureStatusReportRequest,
  SignatureStatusQuery,
} from '../../lib/api'

export const useGetSignatureStatusAnalyticsData = (
  signatureStatusQuery: SignatureStatusQuery,
) => {
  const Api = useApiContext()
  return useQuery(
    'analyticsData',
    async () => {
      const res = await Api.analyticsControllerSignatureStatusReport({
        signatureStatusQuery,
      } as DefaultApiAnalyticsControllerSignatureStatusReportRequest)
      return res.data
    },
    {
      enabled:
        !!signatureStatusQuery &&
        !!signatureStatusQuery.clinicIds &&
        signatureStatusQuery.clinicIds.length > 0 &&
        signatureStatusQuery.dateRange &&
        signatureStatusQuery.dateRange.length > 0 &&
        signatureStatusQuery.columns &&
        signatureStatusQuery.columns.length > 0,
    },
  )
}
