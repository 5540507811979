import { useEffect } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import './styles.css'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security } from '@okta/okta-react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { globalConfig } from './config'
import { ApiContext } from './context/ApiContext'
import { AppContextProvider } from './context/AppContext'
import { FeatureFlagsProvider } from './context/FeatureFlagsContext'
import useApi from './hooks/useApi'
import { queryClient } from './lib/api'
import LoadingPage from './pages/LoadingPage'
import AuthRoutes from './routes/AuthRoutes'
import routes from './routes/config'

declare global {
  interface Window {
    analytics: any
  }
}
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const SecureApp = () => {
  const history = useHistory()

  const { Api } = useApi()
  const runtimeConfig = globalConfig.get()

  const config = {
    clientId: runtimeConfig.OKTA_AUTH_CLIENT_ID,
    issuer: runtimeConfig.OKTA_AUTH_ISSUER,
    redirectUri: `${window.location.origin}${routes.login.callback}`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  }

  const oktaAuth = new OktaAuth(config)
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    localStorage.removeItem('featureFlags')
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  if (oktaAuth === undefined || Api === undefined) {
    return <LoadingPage />
  }

  return (
    <ApiContext.Provider value={Api}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path={routes.login.callback}>
            <LoginCallback loadingElement={<LoadingPage />} />
          </Route>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            )}
            <AppContextProvider>
              <FeatureFlagsProvider>
                <AuthRoutes />
              </FeatureFlagsProvider>
            </AppContextProvider>
          </QueryClientProvider>
        </Switch>
      </Security>
    </ApiContext.Provider>
  )
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <SecureApp />
    </Router>
  )
}

export default App
