import React from 'react'

import {
  HStack,
  Icon,
  IconButton,
  Progress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from '@rhythm/components'

interface FilesTableProps {
  files: File[] | any[]
  showTitle?: boolean
  onDeleteErrorFile?: (index: number) => void
  isUploading?: boolean
}

const FilesTable = ({
  files,
  showTitle = true,
  onDeleteErrorFile,
  isUploading,
}: FilesTableProps): React.ReactElement => {
  function onDelete(index: any) {
    if (onDeleteErrorFile) {
      onDeleteErrorFile(index)
    }
  }
  const msg: string =
    "A file name cannot contain !@#$%^&*()_+-/|=?~;:[]{},.'`<>" + '"' // eslint-disable-line
  return (
    <Table variant="unstyled">
      {showTitle && (
        <TableHead>
          <TableRow>
            <TableHeader px="2xl" py="lg">
              <Text variant="smallCaps" color="text.secondary">
                Report Title
              </Text>
            </TableHeader>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {files.map((file: any, index: number) => (
          <TableRow
            key={`${file.name}-${index}`}
            pb={4}
            bg={showTitle && index % 2 === 0 ? 'neutral.200' : 'neutral.white'}
          >
            <TableCell px="2xl" py={2} style={{ paddingLeft: '0px' }}>
              <HStack spacing="md">
                <Icon icon="file" boxSize="sm" />
                <Text fontWeight="bold">{file.name}</Text>
              </HStack>
              {file.specialChar === true ? (
                <Text style={{ color: 'red' }}>{msg}</Text>
              ) : (
                <Text></Text>
              )}
            </TableCell>
            {!showTitle && (
              <TableCell px="2xl" width="200px" style={{ paddingRight: '3px' }}>
                {file.status === null ? (
                  <Progress size="xs" isIndeterminate />
                ) : (
                  <span style={{ display: 'flex', float: 'right' }}>
                    {file.status === true ? (
                      <>
                        <Icon
                          boxSize="16px"
                          icon="check-circle"
                          background="green"
                          style={{
                            color: '#fff',
                            borderRadius: '50%',
                            border: 'transparent',
                            marginTop: '10px',
                          }}
                        />
                        <b style={{ paddingTop: '10px' }}>Upload Successful</b>
                      </>
                    ) : (
                      <>
                        <Icon
                          boxSize="16px"
                          icon="information-filled"
                          style={{
                            color: '#ED3B3B',
                            borderRadius: '50%',
                            border: 'transparent',
                            marginTop: '10px',
                            marginRight: '3px',
                          }}
                        />
                        <b style={{ paddingTop: '10px' }}>Upload Failed</b>
                      </>
                    )}
                    {!isUploading && (
                      <IconButton
                        aria-label="delete file"
                        icon="delete"
                        variant="plain"
                        onClick={() => onDelete(index)}
                        style={{ paddingBottom: '10px' }}
                      />
                    )}
                  </span>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default FilesTable
