import React, { useEffect, useRef, useState } from 'react'

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@rhythm/components'

import { queryClient, SignAllRequestsStatusEnum } from '../../../../../lib/api'

import { useGetSignAllStatus } from './getSignAllStatus'

interface SignAllPopUpProps {
  isOpen: boolean
  onClose: () => void
}

const SignAllPopUp: React.FC<SignAllPopUpProps> = React.memo(
  ({ isOpen, onClose }) => {
    const { refetch: refetchSignAllStatus } = useGetSignAllStatus()
    const [status, setStatus] = useState<
      SignAllRequestsStatusEnum | undefined
    >()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const counterRef = useRef<number>(0)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
      if (!isOpen) {
        return
      }

      intervalRef.current = setInterval(async () => {
        console.log('Interval triggered')
        try {
          const { data: signAllStatus } = await refetchSignAllStatus()
          console.log(signAllStatus, 'signAllStatus')
          const currentStatus = signAllStatus
            ? signAllStatus[0]?.status
            : undefined
          console.log('Current status:', currentStatus, counterRef.current)
          setStatus(currentStatus)

          counterRef.current += 1
          if (currentStatus === undefined || counterRef.current >= 81) {
            console.log('Interval cleared')
            queryClient.invalidateQueries('TransmissionReportsCountByColor')
            clearInterval(intervalRef.current!)
            onClose()
          }
        } catch (error) {
          setErrorMessage('Could not get sign all status')
          console.log('Interval cleared due to error')
          clearInterval(intervalRef.current!)
          queryClient.invalidateQueries('TransmissionReportsCountByColor')
          onClose()
        }
      }, 6000)

      return () => {
        if (intervalRef.current) {
          console.log(
            'Interval cleared on component unmount or dependencies change',
          )
          clearInterval(intervalRef.current)
        }
      }
    }, [isOpen, refetchSignAllStatus, onClose])

    const renderMessage = () => {
      switch (status) {
        case SignAllRequestsStatusEnum.Initiated:
          return 'Sign All request has been initiated. Do not close or refresh the page'
        case SignAllRequestsStatusEnum.InProgress:
          return 'Sign All request is in process. Do not close or refresh the page'
        case SignAllRequestsStatusEnum.Completed:
          return 'Hurray! Sign All request has been successfully completed. All reports have been signed.'
        case SignAllRequestsStatusEnum.Failed:
          return 'Something went wrong with signing reports. Please try again!'
        default:
          return 'Sign All request has been initiated. Do not close or refresh the page.'
      }
    }

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Spinner size="md" />
          </ModalHeader>
          <ModalBody>
            <div style={{ margin: '0 25px', paddingLeft: '38px' }}>
              <p
                style={{
                  fontSize: '17pt',
                  color: 'grey',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  lineHeight: '1.2',
                }}
              >
                {renderMessage()}
              </p>
              {errorMessage && (
                <p style={{ color: 'red', fontWeight: 'bold' }}>
                  {errorMessage}
                </p>
              )}
            </div>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  },
)

SignAllPopUp.displayName = 'SignAllPopUp'
export default SignAllPopUp
