import { ReactElement } from 'react'

import { BadgeProps } from '@rhythm/components'
import moment from 'moment'

import {
  ScheduleMissedIcon,
  ScheduleNotSetIcon,
  ScheduleRequiresCorrectionIcon,
} from '../../../../../assets'
import {
  DeviceTypes,
  GetScheduledTransmissionsResponse,
  MatchDevice,
  Organization,
} from '../../../../../lib/api'

export interface TransmissionFrequency {
  label: string
  value: string
  freq: number
  transmissions: number
}

export const VENDOR_MAPPING: Record<string, string> = {
  abbott: 'Abbott',
  biotronik: 'Biotronik',
  boston_scientific: 'Boston Scientific',
  medtronic: 'Medtronic',
}
interface OptionType {
  label: string
  value: string
}
export const TRANSMISSION_TYPES: OptionType[] = [
  { label: 'Routine', value: 'routine' },
  { label: 'Combo', value: 'combo_billing' },
  { label: 'ERI', value: 'eri_tracking' },
  { label: 'Heart Failure', value: 'heart_failure' },
]

export const ScheduleTypeLabelMapping: Record<string, string> = {
  routine: 'Routine',
  combo_billing: 'Combo',
  eri_tracking: 'ERI',
  heart_failure: 'Heart Failure',
}

export const getBadgeVariantBasedOnStatus = (status: string) => {
  const statusMapping: Record<string, string> = {
    completed: 'success',
    scheduled: 'success',
    received: 'success',
    processed: 'success',
    missed: 'error',
    unscheduled: 'warning',
  }
  return (statusMapping[status] ?? 'warning') as BadgeProps['variant']
}

export interface DeviceScheduleTableProps {
  id: string
  status: string
  date: string
  type: string
  isEriTracked?: boolean
  isAdHocTransmission: boolean
}

export interface ColumnCellProps {
  row: { original: DeviceScheduleTableProps; index: number; id: string }
  value: string
}

const getStatusBasedOnTransmission = (
  transmissionStatus: string,
  scheduledDate: string,
  transmissionReceived: boolean,
  isReadOnly = true,
) => {
  if (isReadOnly) {
    return 'scheduled'
  } else if (
    transmissionStatus === 'scheduled' &&
    moment.utc(scheduledDate).isBefore(moment.utc(), 'days')
  ) {
    return transmissionReceived ? 'completed' : transmissionStatus
  } else {
    return transmissionStatus
  }
}

export const processFetchedDataForDataTable = (
  transmissions: any[],
  isReadOnly: boolean,
) => {
  const processedData: DeviceScheduleTableProps[] = []

  transmissions.forEach(transmission => {
    console.log('tranny', transmission)
    const {
      id,
      transmission_scheduled_at,
      is_combo_billing_tracked,
      is_eri_tracked,
      is_heart_failure_tracked,
    } = transmission

    const is_routine_tracked =
      !is_combo_billing_tracked && !is_eri_tracked && !is_heart_failure_tracked

    const determineType = () => {
      if (is_heart_failure_tracked) {
        return is_eri_tracked ? 'Heart Failure/ERI' : 'Heart Failure'
      }
      if (is_routine_tracked) {
        return is_eri_tracked ? 'Routine/ERI' : 'Routine'
      }
      if (is_eri_tracked && is_combo_billing_tracked) {
        return 'Combo/ERI'
      }
      return is_eri_tracked ? 'ERI' : 'Combo'
    }

    processedData.push({
      id,
      status: getStatusBasedOnTransmission('', '', false, isReadOnly),
      date: transmission_scheduled_at,
      type: determineType(),
      isEriTracked: is_eri_tracked,
      isAdHocTransmission: false,
    })
  })

  return processedData
}

export const TRANSMISSION_FREQUENCY_MAPPING: Record<string, number> = {
  31: 12,
  35: 10,
  91: 4,
  95: 4,
  105: 3,
  182: 2,
  365: 1,
}

export interface DeviceDetailsCardProps {
  devicesArray: MatchDevice[]
  organizationId: string
  isInActivePatient: boolean
  onViewHistory: () => void
  onEditDevice: () => void
}

export type DeviceIssueType = {
  icon: ReactElement
  text: string
}

export const DEVICE_ISSUE_TYPES: {
  [key: string]: DeviceIssueType
} = {
  deviceScheduleNotSet: {
    icon: <ScheduleNotSetIcon width={24} height={24} />,
    text: 'Device Schedule Not Set',
  },
  deviceScheduleMissed: {
    icon: <ScheduleMissedIcon width={24} height={24} />,
    text: 'Device Transmission Missed',
  },
  deviceRequiresCorrection: {
    icon: <ScheduleRequiresCorrectionIcon width={24} height={24} />,
    text: 'Device Schedule Requires Correction',
  },
}

// =================================================================================================
// =================================================================================================
// ===========================NEW LOGIC FOR SCHEDULED TRANSMISSIONS=================================
// =================================================================================================
// =================================================================================================

export enum TransmissionType {
  HEART_FAILURE = 'heart_failure',
  ROUTINE = 'routine',
  COMBO_BILLING = 'combo_billing',
  ERI_TRACKING = 'eri_tracking',
}

/**
 * This type represents a mapping of transmission types to their corresponding start dates and schedule terms.
 * The transmission types are 'routine', 'heartFailure', and 'comboBilling'.
 * Each transmission type is optional and if provided, it should be an object with 'startDate' and 'scheduleTerm' properties.
 *
 * @typedef {Object} ScheduleTypeWithDateAndTerm
 *
 */
export type ScheduleTypeWithDateAndTerm = {
  [K in 'routine' | 'heart_failure' | 'combo_billing']?: {
    startDate: Date
    termDays: number
  }
}

function snakeCase(str: string): string {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
 * This function creates an array of ScheduledTransmission entities based on the provided parameters.
 * It first iterates over the entries of the transmissionTypesWithScheduleTerm object.
 * For each entry, it extracts the type and the associated startDate and scheduleTerm.
 * It then converts the type to snake case and treats it as a TransmissionType.
 * It calls the generateStandardTransmissions function with the extracted parameters and the provided userId and device.
 * The result is an array of new ScheduledTransmission entities, which are then added to the resultTransmissions array.
 * Finally, it returns the resultTransmissions array.
 *
 * @param {Object} params - The parameters for creating the transmissions.
 * @param {boolean} params.isEriTracked - A boolean indicating if the device is ERI tracked.
 * @param {ScheduleTypeWithDateAndTerm} params.scheduleTypeWithDateAndTerm - An object mapping transmission types to their associated start dates and schedule terms.
 *
 * @returns {DeviceScheduleTableProps[]} - An array of scheduled transmission entities.
 */
export const createUpdatedScheduledTransmissions = ({
  isEriTracked,
  scheduleTypeWithDateAndTerm,
}: {
  isEriTracked: boolean
  scheduleTypeWithDateAndTerm: ScheduleTypeWithDateAndTerm
}): DeviceScheduleTableProps[] => {
  // Initialize an empty array to hold the resultTransmissions.
  const resultTransmissions: DeviceScheduleTableProps[] = []

  // Loop through each transmission type with its schedule term.
  Object.entries(scheduleTypeWithDateAndTerm).forEach(
    ([type, { startDate, termDays }]) => {
      // Convert the type to snake case.
      console.log(
        'processing, type, start date term days',
        type,
        startDate,
        termDays,
      )
      const transmissionType = snakeCase(type) as TransmissionType

      // Check if routine is combined with other feature
      const isRoutineCombinedWithOtherFeature =
        Object.keys(scheduleTypeWithDateAndTerm).length > 1 &&
        Object.keys(scheduleTypeWithDateAndTerm).includes('routine')

      // Generate standard scheduled transmissions.
      const newTransmissions = generateStandardTransmissions(
        termDays,
        startDate,
        transmissionType,
        isEriTracked,
      )

      // Generate ERI transmissions if conditions are met
      let eriTransmissions: DeviceScheduleTableProps[] = []
      if (
        isEriTracked &&
        ![31, 35].includes(termDays) &&
        !isRoutineCombinedWithOtherFeature
      ) {
        eriTransmissions = generateEriTransmissions(
          newTransmissions,
          termDays,
          [35, 95].includes(termDays) ? 35 : 31,
        )
      }

      // Push the new transmissions to the resultTransmissions array.
      resultTransmissions.push(...newTransmissions)

      // Push the ERI transmissions to the resultTransmissions array if device is ERI tracked.
      if (isEriTracked) {
        resultTransmissions.push(...eriTransmissions)
      }
    },
  )

  // sort array based on date
  resultTransmissions.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })

  return resultTransmissions
}
/**
 * Function to generate new standard scheduled transmissions based on the provided parameters.
 *
 * @param {ScheduleTerm} termDays - The new schedule term entity.
 * @param {Date} startDate - The start date for the scheduled transmissions.
 * @param {MatchDevice} device - The device entity associated with the scheduled transmissions.
 * @param {TransmissionType} transmissionType - The type of transmission.
 * @param {string} userId - The ID of the user who created the scheduled transmissions.
 *
 * @param isEriTracked - A boolean indicating if the device is ERI tracked.
 * @returns {DeviceScheduleTableProps[]} - An array of new scheduled transmission entities.
 */
export function generateStandardTransmissions(
  termDays: number,
  startDate: Date,
  transmissionType: TransmissionType,
  isEriTracked = false,
): DeviceScheduleTableProps[] {
  // Determine the number of transmissions based on the term days in the new schedule term.
  const noOfTransmissions =
    TRANSMISSION_FREQUENCY_MAPPING[termDays] ?? Math.floor(365 / termDays)

  // Generate an array of new scheduled transmissions.
  return Array.from({ length: noOfTransmissions }, (_, i) => {
    // Calculate the scheduled date for each transmission.
    const newScheduledDate = moment(startDate)
      .add(i * termDays, 'days')
      .toDate()

    // Return the new scheduled transmission entity.
    return constructNewScheduledTransmission({
      date: newScheduledDate,
      transmissionType,
      isEriTracked,
    })
  })
}

/**
 * Function to generate ERI scheduled transmissions.
 * @param {DeviceScheduleTableProps[]} featureTransmissions - The array of feature transmissions.
 * @param {number} termDays - The term days.
 * @param {number} eriTermDays - The ERI term days.
 * @returns {DeviceScheduleTableProps[]} - An array of ERI scheduled transmissions.
 */
export function generateEriTransmissions(
  featureTransmissions: DeviceScheduleTableProps[],
  termDays: number,
  eriTermDays: number,
): DeviceScheduleTableProps[] {
  const maxERITransmissions =
    (eriTermDays === 35 ? 10 : 12) - TRANSMISSION_FREQUENCY_MAPPING[termDays]
  const maxERITransmissionsPerFeature = Math.ceil(
    maxERITransmissions / TRANSMISSION_FREQUENCY_MAPPING[termDays],
  )
  const eriTransmissions: DeviceScheduleTableProps[] = []

  for (const featureTransmission of featureTransmissions) {
    if (eriTransmissions.length >= maxERITransmissions) {
      break // Stop iterating if we've generated enough ERI transmissions
    }
    const eriStartDate = moment(featureTransmission.date).toDate()
    for (let j = 0; j < maxERITransmissionsPerFeature; j++) {
      const newScheduledDate = moment(eriStartDate)
        .add((j + 1) * eriTermDays, 'days')
        .toDate()
      eriTransmissions.push(
        constructNewScheduledTransmission({
          date: newScheduledDate,
          transmissionType: TransmissionType.ERI_TRACKING,
          isEriTracked: true,
        }),
      )
    }
  }

  return eriTransmissions
}

export const constructNewScheduledTransmission = ({
  date,
  transmissionType,
  isEriTracked = false,
}: {
  date: Date
  transmissionType: TransmissionType
  isEriTracked: boolean
}): DeviceScheduleTableProps => {
  return {
    id: date.toDateString(),
    date: date.toDateString(),
    type:
      transmissionType === 'heart_failure'
        ? isEriTracked
          ? 'Heart Failure/ERI'
          : 'Heart Failure'
        : transmissionType === 'routine'
          ? isEriTracked
            ? 'Routine/ERI'
            : 'Routine'
          : transmissionType === 'eri_tracking'
            ? 'ERI'
            : isEriTracked && transmissionType === 'combo_billing'
              ? 'Combo/ERI'
              : 'Combo',
    status: 'unscheduled',
    isEriTracked: isEriTracked ? isEriTracked : false,
    isAdHocTransmission: false,
  }
}

export const getTermDaysFromOrgBasedOnDeviceType = (
  deviceType: string | undefined,
  organization: Organization | undefined,
): number | undefined => {
  switch (deviceType) {
    case DeviceTypes.CrtD: {
      return organization?.crtdScheduleFrequency?.termDays
    }
    case DeviceTypes.CrtP: {
      return organization?.crtpScheduleFrequency?.termDays
    }
    case DeviceTypes.Icd: {
      return organization?.icdScheduleFrequency?.termDays
    }
    case DeviceTypes.Ilr: {
      return organization?.ilrScheduleFrequency?.termDays
    }
    case DeviceTypes.Pm: {
      return organization?.pmScheduleFrequency?.termDays
    }
    default: {
      return undefined
    }
  }
}

export enum PrimaryCtaText {
  SignReport = 'Sign Report',
  NextTransmission = 'Next Transmission',
  ReturnToDashboard = 'Return to Dashboard',
  SignAndNext = 'Sign and Next',
  LoadingFiles = '..loading files',
}
