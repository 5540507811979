import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  AlertsQuery,
  DefaultApiAnalyticsControllerAlertsReportRequest,
} from '../../lib/api'

export const useGetAlertsAnalyticsData = (alertsQuery: AlertsQuery) => {
  const Api = useApiContext()
  return useQuery(
    'analyticsData',
    async () => {
      const res = await Api.analyticsControllerAlertsReport({
        alertsQuery,
      } as DefaultApiAnalyticsControllerAlertsReportRequest)
      return res.data
    },
    {
      enabled:
        !!alertsQuery &&
        !!alertsQuery.clinicIds &&
        alertsQuery.clinicIds.length > 0 &&
        alertsQuery.dateRange &&
        alertsQuery.dateRange.length > 0 &&
        alertsQuery.columns &&
        alertsQuery.columns.length > 0,
    },
  )
}
