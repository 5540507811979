import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Box, Button, Flex, Heading, Stack } from '@rhythm/components'
import { nanoid } from 'nanoid'
import { toast, ToastContainer } from 'react-toastify'

import { AlertCard } from '../../../../components/AlertCard'
import { NoteFieldValue, Notes } from '../../../../components/Notes'
import { usePatchTransmissionReportDevice } from '../../../../features/transmissionReports'
import {
  Note,
  useGetVendorTransmissionReports,
} from '../../../../features/transmissionReports/api'
import {
  DefaultApiTransmissionReportsControllerCreateNoteRequest,
  TransmissionReportAlert,
  TransmissionReportLocations,
} from '../../../../lib/api/generated-client'
import { getEGMImage } from '../../../../utils/transmissionReportUtil'
import { useTransmissionReportContext } from '../../context'
import {
  ExtendedTransmissionReportImage,
  useTransmissionReportAlerts,
  useTransmissionReportImages,
  useTransmissionReportNotes,
} from '../../hooks'
import { TransmissionReportAlertImages } from '../TransmissionReportAlertImages'

import { VendorDataOverrides } from './../../../../lib/api'

import 'react-toastify/dist/ReactToastify.css'
import './custom-toastify.css'

import TemplateReportDropdown from '../../../../pages/physician/TransmissionReportPage/components/TemplateReportDropdown'
import { FileUploadStoragePathEnum } from '../../../../types'

export const ChildContext = createContext(null)

export interface TransmissionReportAlertsProps {
  max?: number
  callDelete: boolean
}

interface ITransmissionReportAlert extends Partial<TransmissionReportAlert> {
  cardId: string
  alertId?: string
}

const TransmissionAlertsCards = ({
  max = 3,
  callDelete,
}: TransmissionReportAlertsProps) => {
  const componentRef = useRef<HTMLInputElement | null>(null)

  const [marginBottom, setMarginBottom] = useState(0)
  const [marginTop, setMarginTop] = useState(0)

  const { transmissionReport, isLoading: reportLoading } =
    useTransmissionReportContext()
  const { createAlert, updateAlert, deleteAlert } =
    useTransmissionReportAlerts()
  const {
    createNote,
    updateNote,
    deleteNote,
    isLoading: isLoadingNotes,
  } = useTransmissionReportNotes()

  const {
    uploadImage,
    addAlertImage,
    getAlertImages,
    deleteAlertImage,
    isDeletingImage,
  } = useTransmissionReportImages({
    transmissionReport,
  })
  const [alertCards, setAlertCards] = useState<ITransmissionReportAlert[]>([])
  const [isContentExpanded, setIsContentExpanded] = useState(false)
  const existingAlertCardTitles: string[] = alertCards
    .filter(alertCard => alertCard.title !== undefined)
    .map(({ title }) => (title as string).toLowerCase())

  const { id: transmissionReportId, alerts } = transmissionReport ?? {}
  const isRemoteReport = transmissionReport?.transmissionType === 'remote'
  const egmImageOverideUrl: any = transmissionReport
    ? transmissionReport.overrides
    : {}
  const [expandedIndex, setExpandedIndex] = useState(0)
  const [dynamicImage, setDynamicImage] = useState<string | undefined>(
    undefined,
  )

  const onToggleExpanded = (index: number, toggle = true) => {
    if (toggle) setIsContentExpanded(!isContentExpanded)
    setExpandedIndex(index)
  }

  const { data: vendorTransmissionReportsList } =
    useGetVendorTransmissionReports(
      {
        transmissionReportId: transmissionReportId!,
      },
      {
        enabled: (transmissionReportId ?? 0) > 0,
      },
    )

  const handleAddAlertCard = useCallback(() => {
    if (alertCards && alertCards.length < max) {
      const updatedCards = [{ cardId: nanoid(), title: '' }, ...alertCards]
      setAlertCards(updatedCards)
    }
  }, [alertCards, max])

  const handleRemoveAlertCard = useCallback(
    (cardId: string, alertId?: string) => {
      if (alertId && transmissionReportId) {
        deleteAlert({ transmissionReportId, alertId })
      } else if (alertCards && cardId) {
        setAlertCards(alertCards.filter(alert => alert.cardId !== cardId))
      }
    },
    [alertCards, transmissionReportId, deleteAlert],
  )
  const { mutate: patchTransmissionReportDevice } =
    usePatchTransmissionReportDevice()

  const handleRemoveAlertNote = useCallback(
    (noteId: string, note: any) => {
      if (transmissionReportId && noteId) {
        deleteNote({ transmissionReportId, noteId, note } as unknown as Note)
      }
    },
    [transmissionReportId, deleteNote],
  )

  const handleAlertMutation = useCallback(
    async (title: string, alertId?: string) => {
      try {
        if (transmissionReportId && !alertId) {
          await createAlert({
            transmissionReportId,
            transmissionReportAlertCreateParams: { title },
          })
        } else if (transmissionReportId && alertId) {
          await updateAlert({
            transmissionReportId,
            alertId,
            transmissionReportAlertUpdateParams: { title },
          })
        }
      } catch (error) {
        console.log('Error in handleAlertMutation:', error)
      }
    },
    [transmissionReportId],
  )

  const handleSubmit = useCallback(
    (data: { name: keyof VendorDataOverrides; value: string }) => {
      const { name, value } = data
      if (transmissionReport?.id) {
        const transmissionReportOverridesValue = JSON.parse(
          JSON.stringify(transmissionReport.overrides),
        )[data.name]
        if (transmissionReportOverridesValue !== value) {
          const vendorDataOverrides = { [name]: value }
          patchTransmissionReportDevice({
            transmissionReportId: transmissionReport.id,
            vendorDataOverrides,
          })
        }
      }
    },
    [
      patchTransmissionReportDevice,
      transmissionReport?.id,
      transmissionReport?.overrides,
    ],
  )

  const onEgmImageDelete = useCallback(
    (data: { name: keyof VendorDataOverrides; value: string }) => {
      const { name, value } = data
      if (transmissionReport?.id) {
        const vendorDataOverrides = { [name]: value }
        patchTransmissionReportDevice({
          transmissionReportId: transmissionReport.id,
          vendorDataOverrides,
        })
      }
    },
    [patchTransmissionReportDevice, transmissionReport],
  )

  const onEgmImageDeleteSubmit = useCallback(
    (value: string) => {
      onEgmImageDelete({
        name: `egmImageStatus` as keyof VendorDataOverrides,
        value,
      })
    },
    [onEgmImageDelete],
  )

  const onEgmImageSubmit = useCallback(
    (value: string) => {
      console.log('onEgmImageSubmit', value)
      handleSubmit({
        name: `egmImage` as keyof VendorDataOverrides,
        value,
      })
    },
    [handleSubmit],
  )

  useEffect(() => {
    const toastListener = toast.onChange(isVisible => {
      setMarginBottom(isVisible ? 107 : 0)
      setMarginTop(isVisible ? -35 : 0)
    })
    return () => {
      toastListener()
    }
  }, [callDelete, transmissionReport?.notes])

  useEffect(() => {
    setDynamicImage(undefined)
    const requiredImageInfo = getEGMImage(vendorTransmissionReportsList)
    if (requiredImageInfo?.url && requiredImageInfo.name) {
      setDynamicImage(requiredImageInfo.url)
      onEgmImageSubmit(requiredImageInfo.name)
      return
    }
    setDynamicImage(undefined)
    // eslint-disable-next-line
  }, [vendorTransmissionReportsList])

  useEffect(() => {
    if (!alerts || reportLoading) {
      return
    }

    const requiredAlerts = alerts.map(({ id, title, notes }) => ({
      alertId: id,
      title,
      cardId: nanoid(),
      notes,
    }))
    if (requiredAlerts.length === 0 && isRemoteReport) {
      handleAlertMutation('Presenting')
      setAlertCards([
        { cardId: nanoid(), title: 'Presenting' },
        ...requiredAlerts,
      ])
      return
    }
    setAlertCards([...requiredAlerts])
  }, [alerts, handleAlertMutation, isRemoteReport, reportLoading])

  const handleNoteMutation = useCallback(
    ({ id, note }: NoteFieldValue, alertId?: string) => {
      if (transmissionReportId && alertId && !id) {
        const alertNote = {
          location: TransmissionReportLocations.Alert,
          note,
          alertId,
        }
        createNote({
          transmissionReportId,
          flag: false,
          transmissionReportNoteCreateParams: alertNote,
          alertId,
        } as unknown as DefaultApiTransmissionReportsControllerCreateNoteRequest)
      } else if (transmissionReportId && id) {
        updateNote({
          transmissionReportId,
          noteId: id,
          transmissionReportNoteUpdateParams: { note },
        })
      }
    },
    [transmissionReportId, updateNote, createNote],
  )

  const handleImageUpload = useCallback(
    async (image: File, alertId?: string): Promise<boolean> => {
      try {
        if (image && alertId) {
          const {
            data: { file },
          } = await uploadImage({
            file: image,
            path: FileUploadStoragePathEnum.TransmissionReportImage,
          })

          if (file && transmissionReportId) {
            await addAlertImage({
              transmissionReportId,
              alertId,
              transmissionReportAddAlertImageParams: {
                fileId: file.id,
              },
            })
            return true // Upload and addition successful
          } else {
            return false // Either file or transmissionReportId is missing
          }
        } else {
          return false // Either image or alertId is missing
        }
      } catch (error) {
        console.error('Error occurred during image upload:', error)
        return false // Error occurred during the process
      }
    },
    [uploadImage, transmissionReportId, addAlertImage],
  )

  const handleImageDelete = useCallback(
    async (image: ExtendedTransmissionReportImage, alertId?: string) => {
      try {
        setIsContentExpanded(false)
        if (image && alertId && transmissionReportId) {
          const {
            file: { id: fileId },
          } = image
          const imageToDelete = alerts
            ?.find(alert => alert.id === alertId)
            ?.images.find(image => image.image.id === fileId)

          if (imageToDelete?.id) {
            await deleteAlertImage({
              transmissionReportId,
              alertId,
              imageId: imageToDelete?.id,
            })
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } catch (err) {
        console.log('An error occurred during image deletion: ', err)
        return false
      }
    },
    [transmissionReportId, deleteAlertImage, alerts],
  )

  return (
    <Box w="full" ref={componentRef}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={alertCards.length > 0 ? '3xl' : ''}
      >
        <Heading variant="h6">Clinical Interpretation</Heading>
        <Flex justifyContent="space-between" alignItems="center">
          {isRemoteReport && (
            <TemplateReportDropdown dropDownLabel="Select Template" />
          )}
          <Button
            onClick={handleAddAlertCard}
            disabled={alertCards.length >= max}
            ml="2xl"
          >
            Add an Alert Card
          </Button>
        </Flex>
      </Flex>
      <Flex
        className="toast-container"
        style={{ marginBottom: marginBottom, marginTop: marginTop }}
      >
        {' '}
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          closeOnClick
          style={{}}
          icon={false}
        />
      </Flex>
      {alertCards && alertCards.length > 0
        ? alertCards.map(({ alertId, title, cardId, notes }, index) => {
            return (
              <AlertCard
                key={`${cardId}-alert-card-${index}`}
                canDelete={title?.toLowerCase() !== 'presenting'}
                isLastCard={index === alertCards.length - 1 && isRemoteReport}
                _notLast={{ mb: 'xl' }}
                onDelete={() => handleRemoveAlertCard(cardId, alertId)}
                title={title}
                onTitleSubmit={title => {
                  if (title && !existingAlertCardTitles.includes(title)) {
                    handleAlertMutation(title, alertId)
                  }
                }}
                existingTitles={existingAlertCardTitles}
              >
                <Stack
                  alignItems="top"
                  spacing="4xl"
                  direction={isContentExpanded ? 'column' : 'row'}
                >
                  <Flex
                    width={isContentExpanded ? '100%' : '50%'}
                    direction="column"
                    alignItems="stretch"
                  >
                    <Notes
                      notes={notes}
                      groupName={`alert-card-${cardId}-notes`}
                      onSubmit={note => handleNoteMutation(note, alertId)}
                      onDelete={handleRemoveAlertNote}
                      canAdd={(!!alertId && !!title) || isLoadingNotes}
                      alertId={alertId}
                      isLoading={isLoadingNotes || reportLoading}
                    />
                  </Flex>
                  <Flex
                    width={isContentExpanded ? '100%' : '50%'}
                    direction="column"
                    alignItems="stretch"
                  >
                    <TransmissionReportAlertImages
                      images={getAlertImages(alertId)}
                      onToggleExpanded={onToggleExpanded}
                      isContentExpanded={isContentExpanded}
                      expandedIndex={expandedIndex}
                      cardTitle={title}
                      imagesWithUrl={dynamicImage}
                      egmImageOverideUrl={egmImageOverideUrl.egmImage}
                      onEgmImageDelete={value => onEgmImageDeleteSubmit(value)}
                      onImageUpload={image => handleImageUpload(image, alertId)}
                      onImageDelete={image => handleImageDelete(image, alertId)}
                      canUpload={!!alertId}
                      isDeletingImage={isDeletingImage}
                    />
                  </Flex>
                </Stack>
              </AlertCard>
            )
          })
        : null}
    </Box>
  )
}

export default TransmissionAlertsCards
