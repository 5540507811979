import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerUpdateAlertRequest,
  TransmissionReport,
} from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const useUpdateTransmissionReportAlert = () => {
  const Api = useApiContext()
  return useMutation(
    (
      transmissionReportAlertCreateParams: DefaultApiTransmissionReportsControllerUpdateAlertRequest,
    ) =>
      Api.transmissionReportsControllerUpdateAlert(
        transmissionReportAlertCreateParams,
      ),
    {
      onSuccess: (data, variables) => {
        const { transmissionReportId, alertId } = variables
        const {
          data: { alert },
        } = data

        queryClient.setQueryData<Partial<TransmissionReport | undefined>>(
          ['transmissionReport', transmissionReportId],
          transmissionReport => {
            if (transmissionReport?.alerts && alert) {
              const alertIndex = transmissionReport.alerts.findIndex(
                alert => alert.id === alertId,
              )
              transmissionReport.alerts[alertIndex].title = alert.title
            }
            return transmissionReport
          },
        )
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
