import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  DefaultApiAnalyticsControllerSchedulingReportRequest,
  SchedulingQuery,
} from '../../lib/api'

export const useGetSchedulingAnalyticsData = (
  schedulingQuery: SchedulingQuery,
) => {
  const Api = useApiContext()
  return useQuery(
    'analyticsData',
    async () => {
      const res = await Api.analyticsControllerSchedulingReport({
        schedulingQuery,
      } as DefaultApiAnalyticsControllerSchedulingReportRequest)
      return res.data
    },
    {
      enabled:
        !!schedulingQuery &&
        !!schedulingQuery.clinicIds &&
        schedulingQuery.clinicIds.length > 0 &&
        schedulingQuery.dateRange &&
        schedulingQuery.dateRange.length > 0 &&
        schedulingQuery.columns &&
        schedulingQuery.columns.length > 0,
    },
  )
}
