import {
  Box,
  Card,
  Checkbox,
  Error,
  Flex,
  Form,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  SkeletonProps,
  Spinner,
  Stack,
  VStack,
} from '@rhythm/components'

import './BillingCardStyle.css'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import MultiSelect, { components, OptionProps } from 'react-select'

import DatePickerv2 from '../../../../../components/DatePickerv2'
import {
  useTransmissionReportBilling,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { BillingObject } from '../../../../../lib/api'
import {
  diagnosis_OPTIONS,
  diagnosisDefaultValueByDeviceType,
  Professional_OPTIONS_IN_CLINIC,
  Professional_OPTIONS_MANUAL,
  Professional_OPTIONS_REMOTE,
  TECHNICAL_OPTIONS,
  Technical_OPTIONS_Heart_Failure,
  Technical_OPTIONS_ILR,
} from '../../../../../utils/constants/transmissionReports'
import formatDate from '../../../../../utils/formatDate'
import getZeroHourDbDate from '../../../../../utils/getZeroHourDbDate'
import { DefaultMultiSelectStyles } from '../../../../../utils/multiSelectOptions'
import { DATE_FORMAT_REGEX } from '../../../../../utils/regex'

import { DollarSignIcon, NavigationIcon } from './CustomIcons'

interface AccountPartial {
  billingType: string
}

interface LabelWithValue {
  label: string
  value: string
  description?: string
}

const OptionWithDescription = (props: OptionProps<LabelWithValue, true>) => {
  return (
    <components.Option {...props}>
      <HStack gap={2}>
        <span
          style={{
            display: 'inline-block',
            maxWidth: '3em',
          }}
        >
          {props.data.label}
        </span>
        <span>{props.data.description}</span>
      </HStack>
    </components.Option>
  )
}

const BillingCard = (): React.ReactElement => {
  const datePickerWrapperRef = useRef<HTMLDivElement>(null)
  const {
    transmissionReport,
    isLoading,
    setIsBillingValid,
    isBillingValid,
    isBillingLoading,
    setIsBillingLoading,
    currentTemplateType,
  } = useTransmissionReportContext()

  const { createBilling, updateBilling } = useTransmissionReportBilling()
  const { billing, id: transmissionReportId } = transmissionReport ?? {}
  const isFFS =
    (transmissionReport?.account as unknown as AccountPartial)?.billingType ===
    'fee_for_service'
  const transmissionType = transmissionReport?.transmissionType
  const flagHeartFailure = transmissionReport?.flagHeartFailure ?? false
  const isBillable = transmissionReport?.isBillable
  const deviceType = transmissionReport?.device?.deviceType ?? ''
  const [technicalOptions, setTechnicalOptions] = useState(TECHNICAL_OPTIONS)

  const [professional_OPTIONS, setProfessional_OPTIONS] = useState(
    Professional_OPTIONS_REMOTE,
  )
  const { currentUser } = useCurrentUser()
  const appDeterminedBillableUi = currentUser.isExternal
    ? transmissionReport?.account?.appDeterminedBillableUI
    : true

  const defaultValues: Record<string, any> =
    billing?.billingobject as BillingObject

  const { id: billingId } = billing ?? {}

  const methods = useForm({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    reset,
    getValues,
  } = methods

  const handleBillingMutation = useCallback(
    async (data: BillingObject) => {
      try {
        setIsBillingLoading(true)

        if (transmissionReportId && !billingId) {
          await createBilling(
            {
              transmissionReportId,
              transmissionReportBillingCreateParams: { billingobject: data },
            },
            {
              onSettled: () => setIsBillingLoading(false),
            },
          )
        } else if (transmissionReportId && billingId) {
          await updateBilling(
            {
              transmissionReportId,
              billingId,
              transmissionReportBillingUpdateParams: { billingobject: data },
            },
            {
              onSettled: () => setIsBillingLoading(false),
            },
          )
        } else {
          setIsBillingLoading(false)
        }
      } catch (error) {
        console.log('Error in handleBillingMutation:', error)
        setIsBillingLoading(false)
      }
    },
    [
      setIsBillingLoading,
      transmissionReportId,
      billingId,
      createBilling,
      updateBilling,
    ],
  )

  useEffect(() => {
    if (transmissionType === 'remote')
      setProfessional_OPTIONS(Professional_OPTIONS_REMOTE)
    else if (transmissionType === 'in-clinic')
      setProfessional_OPTIONS(Professional_OPTIONS_IN_CLINIC)
    //manual case. In clinic set has remote set as it's subset.
    else setProfessional_OPTIONS(Professional_OPTIONS_MANUAL)

    if (deviceType === 'ILR') {
      setTechnicalOptions(Technical_OPTIONS_ILR)
    } else if (flagHeartFailure) {
      setTechnicalOptions(Technical_OPTIONS_Heart_Failure)
    } else {
      setTechnicalOptions(TECHNICAL_OPTIONS)
    }
    if (transmissionType === 'remote' || transmissionType === 'manual')
      setTechnicalOptions(TECHNICAL_OPTIONS)
    else setTechnicalOptions([{ label: '-', value: '-', description: '' }])
    methods.setValue('diagnosis_code', '')
    if (defaultValues) {
      reset(defaultValues)
    } else if (typeof defaultValues === 'undefined') {
      const emptyObject = {
        professional_code: '',
        technical_code: '',
        diagnosis_code: '',
        billing_date: '',
      }
      reset(emptyObject)
      methods.setValue('billable', !!isBillable)
    }
    if (
      typeof defaultValues === 'undefined' ||
      defaultValues?.diagnosis_code === undefined
    ) {
      const diagnosis_code = diagnosisDefaultValueByDeviceType(deviceType)
      let defaultDiagnosisVal = []
      if (diagnosis_code !== '') {
        defaultDiagnosisVal = [{ label: diagnosis_code, value: diagnosis_code }]
        methods.setValue('diagnosis_code', defaultDiagnosisVal)
        handleSubmit(handleBillingMutation)()
      }
    }
  }, [
    defaultValues,
    methods,
    deviceType,
    flagHeartFailure,
    isBillable,
    reset,
    handleSubmit,
    handleBillingMutation,
    transmissionType,
  ])

  const Skel = useMemo(
    // eslint-disable-next-line react/display-name
    () => (args: SkeletonProps) => <Skeleton isLoaded={!isLoading} {...args} />,
    [isLoading],
  )

  const watchBillingDate = watch('billing_date')
  const watchProfessionalCode = watch('professional_code')
  const watchDiagnosisCode = watch('diagnosis_code')
  const watchTechnical_code = watch('technical_code')

  const setDefaultBillableCodes = useCallback(() => {
    let existingProfessionalCode = getValues('professional_code')
    let existingTechnicalCode = getValues('technical_code')
    const existingBillable = getValues('billable')
    if (!existingBillable) {
      if (existingProfessionalCode) {
        methods.setValue('professional_code', '')
      }
      if (existingTechnicalCode) {
        methods.setValue('technical_code', '')
      }
      handleSubmit(handleBillingMutation)()
      return
    }
    if (!flagHeartFailure) {
      existingProfessionalCode = []
      existingTechnicalCode = ''
    }
    const setCodesIfEmpty = (
      professionalCodeValue: string,
      technicalCodeValue: string,
    ) => {
      if (!existingProfessionalCode || existingProfessionalCode.length === 0) {
        methods.setValue('professional_code', [
          { label: professionalCodeValue, value: professionalCodeValue },
        ])
      }
      if (!existingTechnicalCode || existingTechnicalCode.length === 0) {
        methods.setValue('technical_code', {
          label: technicalCodeValue,
          value: technicalCodeValue,
        })
      }
    }
    if (isFFS && (flagHeartFailure || ['ILR'].includes(deviceType))) {
      // If billingTpe is FFS, set specific professional codes and leave technical code blank
      if (flagHeartFailure) {
        methods.setValue('professional_code', [
          { label: '93297', value: '93297' },
        ])
      } else if (['ILR'].includes(deviceType)) {
        methods.setValue('professional_code', [
          { label: '93298', value: '93298' },
        ])
      }
      // Set technical code to blank
      methods.setValue('technical_code', { label: '-', value: '-' })
      handleSubmit(handleBillingMutation)()
    } else {
      if (flagHeartFailure) {
        if (
          ['PM', 'CRT-P', 'CRTP', 'ICD', 'CRT-D', 'CRTD'].includes(deviceType)
        ) {
          if (
            existingProfessionalCode.length !== 1 ||
            existingProfessionalCode[0]?.value !== '93297-26'
          )
            methods.setValue('professional_code', [
              { label: '93297-26', value: '93297-26' },
            ])
          if (existingTechnicalCode?.value !== '93297-TC')
            methods.setValue('technical_code', {
              label: '93297-TC',
              value: '93297-TC',
            })
        }
      } else {
        switch (deviceType) {
          case 'PM':
          case 'CRT-P':
          case 'CRTP':
            setCodesIfEmpty('93294', '93296')
            break
          case 'ICD':
          case 'CRT-D':
          case 'CRTD':
            setCodesIfEmpty('93295', '93296')
            break
          case 'ILR':
            setCodesIfEmpty('93298-26', '93298-TC')
            break
          default:
            break
        }
      }
      handleSubmit(handleBillingMutation)()
    }
  }, [
    getValues,
    flagHeartFailure,
    isFFS,
    deviceType,
    handleSubmit,
    handleBillingMutation,
    methods,
  ])

  useEffect(() => {
    setDefaultBillableCodes()
  }, [flagHeartFailure, setDefaultBillableCodes])

  const autoPopulateBillableFields = useCallback(() => {
    const professionalCodeExists = !!(getValues('professional_code') ?? false)
    const technicalCodeExists = !!(getValues('technical_code') ?? false)
    const billableInBillingObject =
      transmissionReport?.billing?.billingobject.billable
    if (
      isBillable &&
      !!billableInBillingObject &&
      (!professionalCodeExists || !technicalCodeExists)
    ) {
      setDefaultBillableCodes()
    }
  }, [
    getValues,
    transmissionReport?.billing?.billingobject.billable,
    isBillable,
    setDefaultBillableCodes,
  ])
  /* eslint-enable */

  // Inside your component
  useEffect(() => {
    const isBillingValidVal =
      !!watchBillingDate &&
      !!watchDiagnosisCode &&
      watchDiagnosisCode.length > 0 &&
      ((getValues('billable') &&
        !!watchProfessionalCode &&
        watchProfessionalCode.length > 0 &&
        !!watchTechnical_code) ||
        !getValues('billable'))

    if (isBillingValidVal !== isBillingValid)
      setIsBillingValid(isBillingValidVal)
    autoPopulateBillableFields()
    const [updatedField] = Object.keys(dirtyFields)
    // already updating 'billable' field on change
    if ((!!updatedField && updatedField !== 'billable') || flagHeartFailure) {
      handleSubmit(handleBillingMutation)()
    }
  }, [
    dirtyFields,
    flagHeartFailure,
    isBillingValid,
    watchBillingDate,
    watchDiagnosisCode,
    watchProfessionalCode,
    watchTechnical_code,
    autoPopulateBillableFields,
    getValues,
    handleBillingMutation,
    handleSubmit,
    setIsBillingValid,
  ])

  const isBillableChecked = getValues('billable')
  const diagnosisErrMsg =
    !watchDiagnosisCode || watchDiagnosisCode?.length === 0
      ? 'Please select the Diagnosis code'
      : undefined
  const billingDateErrMsg = !watchBillingDate
    ? 'Please select the Billing Date'
    : undefined
  const professionalErrMsg =
    getValues('billable') &&
    (!watchProfessionalCode || watchProfessionalCode?.length === 0)
      ? 'Please select the Professional code'
      : undefined

  const technicalErrMsg =
    getValues('billable') &&
    (!watchTechnical_code || watchTechnical_code?.length === 0)
      ? 'Please select the Technical code'
      : undefined

  const selectOptionStyles = useMemo(
    () => DefaultMultiSelectStyles<LabelWithValue>(),
    [],
  )

  return (
    <Card width="100%">
      <Flex direction="column">
        <HStack mb="2xl" spacing="xl">
          <div id="dollarSign">
            <DollarSignIcon />
          </div>
          <VStack alignItems="flex-start" spacing="sm" width={'100%'}>
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Skel>
                <Heading variant="h4">Billing</Heading>
              </Skel>
              <span>
                {isBillable && appDeterminedBillableUi && (
                  <HStack>
                    <Icon
                      icon="information"
                      boxSize={6}
                      fontSize={12}
                      id="infoIconStyle"
                      color={'black'}
                    />
                    <div>
                      <strong>
                        App has determined this report to be billable
                      </strong>
                    </div>
                  </HStack>
                )}
              </span>
              <Stack
                spacing="sm"
                direction="row"
                alignContent={'flex-end'}
                align={'flex-end'}
              >
                <Icon
                  icon="information"
                  boxSize={4}
                  fontSize={12}
                  id="infoIconStyle"
                  color={'#455468'}
                />
                <FormLabel margin={0}>Billing and Coding:</FormLabel>

                <Link as={'div'} fontSize={'sm'}>
                  <HStack
                    onClick={() =>
                      window.open(
                        'https://www.cms.gov/medicare-coverage-database/view/article.aspx?articleId=56602',
                        '_blank',
                      )
                    }
                  >
                    <Box>{'Cardiac Rhythm Device Evaluation'}</Box>
                    <NavigationIcon />
                  </HStack>
                </Link>
              </Stack>
            </HStack>
          </VStack>
        </HStack>

        {isBillingLoading ? (
          <Flex justifyContent="center" alignItems="center" height="170px">
            <Spinner size="xl" color="primary.400" />
          </Flex>
        ) : (
          <Form methods={methods} onSubmit={() => {}}>
            <VStack spacing="2xl">
              <Stack spacing="lg" direction="row" width="100%">
                <FormControl maxWidth={130}>
                  <FormLabel htmlFor="mrn2" mb="md">
                    BILLING STATUS
                  </FormLabel>
                  <FormControl>
                    <Controller
                      name="billable"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Checkbox
                          ref={ref}
                          isChecked={value}
                          value={value}
                          onChange={e => {
                            onChange(e)
                            if (!e.target.checked) {
                              methods.setValue('professional_code', '')
                              methods.setValue('technical_code', '')
                            }
                            setDefaultBillableCodes()
                          }}
                        />
                      )}
                    />
                    &nbsp;&nbsp;<span>Billable</span>
                  </FormControl>
                </FormControl>
                <FormControl maxWidth={424}>
                  <FormLabel htmlFor="diagnosis_code" mb="md">
                    DIAGNOSIS CODE
                  </FormLabel>
                  <Controller
                    name="diagnosis_code"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <MultiSelect
                        ref={ref}
                        value={value}
                        options={diagnosis_OPTIONS}
                        isDisabled={isLoading}
                        onChange={onChange}
                        styles={selectOptionStyles}
                        isMulti
                      />
                    )}
                  />
                  {diagnosisErrMsg && <Error message={diagnosisErrMsg} />}
                </FormControl>
                <FormControl maxWidth={424}>
                  <FormLabel htmlFor="billing_date" mb="md">
                    BILLING DATE
                  </FormLabel>
                  <Controller
                    name="billing_date"
                    control={control}
                    defaultValue={defaultValues?.billing_date}
                    render={({ field: { onChange, value } }) => (
                      <Box ref={datePickerWrapperRef} id="billingBox">
                        <DatePickerv2
                          onlyOpenCalenderOnIconClick={true}
                          datePickerProps={{
                            id: 'billing_date',
                            selected: value ? getZeroHourDbDate(value) : null,
                            onChange: (date: Date | null) => {
                              onChange(
                                date ? formatDate(date.toISOString()) : null,
                              )
                            },
                            placeholderText: 'mm/dd/yyyy',
                            onlyOpenCalenderOnIconClick: true,
                            onFocus: e => e.target.blur(),
                            isClearable: true,
                            disabledKeyboardNavigation: true,
                            dayClassName: (date: Date) => {
                              const today = new Date()
                              return !value &&
                                formatDate(date.toISOString()) ===
                                  formatDate(today.toISOString())
                                ? 'currentDay'
                                : ''
                            },
                          }}
                        />
                      </Box>
                    )}
                    rules={{
                      pattern: {
                        value: DATE_FORMAT_REGEX,
                        message:
                          'Date is invalid or has wrong format (month/day/year)',
                      },
                    }}
                  />
                  {billingDateErrMsg && <Error message={billingDateErrMsg} />}
                </FormControl>
              </Stack>
              <Stack spacing="lg" direction="row" width="100%">
                <FormControl maxWidth={130}></FormControl>

                <FormControl hidden={!isBillableChecked} maxWidth={424}>
                  <FormLabel htmlFor="professional_code" mb="md">
                    PROFESSIONAL CODE
                  </FormLabel>
                  <Controller
                    name="professional_code"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <MultiSelect
                        ref={ref}
                        value={value}
                        options={professional_OPTIONS}
                        isDisabled={isLoading}
                        components={{
                          Option: OptionWithDescription,
                        }}
                        styles={selectOptionStyles}
                        onChange={onChange}
                        isMulti
                      />
                    )}
                  />
                  {professionalErrMsg && <Error message={professionalErrMsg} />}
                </FormControl>
                <FormControl hidden={!isBillableChecked} maxWidth={424}>
                  <FormLabel htmlFor="technical_code" mb="md">
                    TECHNICAL CODE
                  </FormLabel>
                  <Controller
                    name="technical_code"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Box style={{ position: 'relative' }}>
                        <MultiSelect
                          ref={ref}
                          value={value}
                          options={technicalOptions}
                          components={{
                            Option: OptionWithDescription,
                          }}
                          styles={selectOptionStyles}
                          isDisabled={isLoading}
                          onChange={onChange}
                        />
                        {value && (
                          <Icon
                            style={{
                              position: 'absolute',
                              right: '40px',
                              top: '12px',
                              height: '16px',
                              width: '16px',
                              cursor: 'pointer',
                            }}
                            icon="close-circle"
                            onClick={() => {
                              onChange({ target: { value: '' } })
                              setTimeout(() => {
                                handleBillingMutation(getValues())
                              }, 200)
                            }}
                          />
                        )}
                      </Box>
                    )}
                  />
                  {technicalErrMsg && <Error message={technicalErrMsg} />}
                </FormControl>
              </Stack>
            </VStack>
          </Form>
        )}
      </Flex>
    </Card>
  )
}

export default BillingCard
