import '@fontsource/bad-script'

import React, { useMemo } from 'react'

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { getDefaultTransmissionValuesForPatient } from '../../../../../features/transmissionReports'
import { Patient, TransmissionReportDto } from '../../../../../lib/api'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import { capitalizeFirstCharacter } from '../PatientDemographicsCard/PatientDemographicsCard'

import { referringProvider } from './CreatingPDFGrey'
import device from './device.png'
import patient from './patient.png'
import Rhythm_logo from './Rhythm_logo.png'

export interface CreatingPDFProps {
  patient?: Patient
  transmissionReport?: TransmissionReportDto
  urlImg1: any
  urlImg2: any
  urlImg3: any
  urlImg4: any
  urlImg5: any
  urlImg6: any
  urlImgEpisode1: any
  urlImgEpisode2: any
  urlImgEpisode3: any
  urlegm: any
  practitionGet: any
  deviceType: any
  clinicAddress: any
  showEditedByInfoOnPdf: any
  editedUser: any
  editedDate: any
}

const CreatingILRPDF = ({
  transmissionReport,
  urlImg1,
  urlImg2,
  urlImg3,
  urlImg4,
  urlImg5,
  urlImg6,
  urlImgEpisode1,
  urlImgEpisode2,
  urlImgEpisode3,
  urlegm,
  practitionGet,
  deviceType,
  clinicAddress,
  showEditedByInfoOnPdf,
  editedUser,
  editedDate,
}: CreatingPDFProps): React.ReactElement => {
  const { givenName, familyName, monitorReason, diagnosisOption } =
    transmissionReport?.patient || {}
  Font.registerHyphenationCallback(familyName => [familyName])

  const editedDateUtc = formatUtcDate(editedDate)
  const editedTimeWithTimezone = formatUtcDate(editedDate, 'hh:mm A UTC')

  const deviceSerialNumber = transmissionReport?.device?.deviceSerialNumber
  const deviceModelNumber = transmissionReport?.device?.deviceModelNumber
  const deviceImplantDate = transmissionReport?.device?.deviceImplantDate

  const clinicfax = transmissionReport?.patient?.clinic.faxNumber
  const clinicphoneNumber = transmissionReport?.patient?.clinic.phoneNumber
  const clinicname = transmissionReport?.patient?.clinic.name
  const commaccount = transmissionReport?.account?.sentToEhr
  const comm = transmissionReport?.comment
  let faxx = ''
  if (clinicfax) {
    faxx = 'Fax: '
  }

  let tele = ''
  if (clinicphoneNumber) {
    tele = 'T.: '
  }
  let devvv = ''
  if (deviceType) {
    devvv = deviceType
  }

  const fetchClinicAddress = clinicAddress?.organization?.address

  const city = fetchClinicAddress?.city

  const line1 = fetchClinicAddress?.line1

  let line2 = fetchClinicAddress?.line2
  if (line2) {
    line2 = ', ' + line2
  }

  let postalCode = fetchClinicAddress?.postalCode
  if (postalCode) {
    postalCode = ', ' + postalCode
  }

  let state = fetchClinicAddress?.state
  if (state) {
    state = ', ' + state
  }

  const transmissionReportAlerts = transmissionReport?.alerts
  const { vendorName } = transmissionReport?.deviceVendor || {}

  const alertNotes = transmissionReport?.notes
  const billingData = transmissionReport?.billing?.billingobject

  let diagnosiscodeJson
  let diagnosiscodeParse
  let diagnosiscodevalue
  const diagnosiscode = billingData?.diagnosis_code
  if (diagnosiscode) {
    diagnosiscodeJson = JSON.stringify(diagnosiscode)
    diagnosiscodeParse = JSON.parse(diagnosiscodeJson)
    diagnosiscodevalue = diagnosiscodeParse[0]?.value
  }
  if (diagnosiscodeParse) {
    for (let fvg = 1; fvg < diagnosiscodeParse.length; fvg++) {
      diagnosiscodevalue =
        diagnosiscodevalue + ', ' + diagnosiscodeParse[fvg].value
    }
  }
  let professionalcodeJson
  let professionalcodeParse
  let professionalcodevalue
  const professionalcode = billingData?.professional_code
  if (professionalcode) {
    professionalcodeJson = JSON.stringify(professionalcode)
    professionalcodeParse = JSON.parse(professionalcodeJson)
    professionalcodevalue = professionalcodeParse[0]?.value
  }

  if (professionalcodeParse) {
    for (let fvg = 1; fvg < professionalcodeParse.length; fvg++) {
      professionalcodevalue =
        professionalcodevalue + ', ' + professionalcodeParse[fvg].value
    }
  }
  let signatureSorting: any = ''
  if (
    transmissionReport?.signatures &&
    transmissionReport?.signatures.length > 1
  ) {
    signatureSorting = transmissionReport?.signatures.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    )
  }
  const signatures =
    signatureSorting === '' ? transmissionReport?.signatures : signatureSorting
  const signs = signatures
  let practionerForPdf: string = ''
  const practionerDetails = transmissionReport?.patient?.practitioner

  let alertName1 = ''
  let alertName2 = ''
  let alertName3 = ''
  let sign1Date
  let sign2Date
  let sign1Family
  let sign1Given
  let sign2Family
  let sign2Given
  let sign1Credentials
  let sign2Credentials
  let sign1Text
  let sign2Text

  let practitionerValue
  if (practitionGet) {
    for (let tcv = 0; tcv < practitionGet.length; tcv++) {
      practitionerValue = practitionGet[tcv].label
    }
  }

  if (practionerDetails) {
    practionerForPdf = `${practionerDetails.given} ${practionerDetails.family}`
  } else {
    practionerForPdf = practitionerValue
  }

  if (signs) {
    for (let i = 0; i < signs.length; i++) {
      if (i === 0) {
        sign1Family = signs[i].user.family
        sign1Given = signs[i].user.given
        const d1 = formatUtcDate(signs[i].createdAt)
          ? formatUtcDate(signs[i].createdAt)
          : ''
        sign1Date = signs[i].signDate
          ? signs[i].signDate.replaceAll('-', '/')
          : d1
        sign1Credentials = signs[i].user?.credentials?.join(', ')
        sign1Text = signs[1]?.signDate
          ? 'Electronically signed by:'
          : 'Reviewed by Technician:'
        sign2Text = signs[1]?.signDate
          ? 'Reviewed by Technician:'
          : 'Electronically signed by:'
      } else {
        sign2Family = signs[i].user.family
        sign2Given = signs[i].user.given
        const d2 = formatUtcDate(signs[i].createdAt)
          ? formatUtcDate(signs[i].createdAt)
          : ''
        sign2Date = signs[i].signDate
          ? signs[i].signDate.replaceAll('-', '/')
          : d2
        sign2Credentials = signs[i].user?.credentials?.join(', ')
        sign2Text = signs[1]?.signDate
          ? 'Reviewed by Technician:'
          : 'Electronically signed by:'
      }
    }
  }
  if (sign1Family === undefined) {
    sign1Family = ''
  }
  if (sign1Given === undefined) {
    sign1Given = ''
  }
  if (sign1Credentials === undefined) {
    sign1Credentials = ''
  }

  if (sign2Family === undefined) {
    sign2Family = ''
  }
  if (sign2Given === undefined) {
    sign2Given = ''
  }
  if (sign2Credentials === undefined) {
    sign2Credentials = ''
  }

  let transmissionCount = 0
  let transmissionReportAlertsnote = 0
  let transmissionReportAlertsnoteTwo = 0
  let alertNoteCount = 0
  let batteryNoteCount = 0
  let moniteryValuesNoteCount = 0
  let episodeAlertsnote = 0

  let epiCard1 = ''
  let epiCard2 = ''
  let epiCard3 = ''
  let epiCard4 = ''
  let epiCard5 = ''

  let monitNote1
  let monitNote2
  let monitNote3
  let monitNote4
  let monitNote5

  let batNote1
  let batNote2
  let batNote3
  let batNote4
  let batNote5

  let altNote1
  let altNote2
  let altNote3
  let altNote4
  let altNote5
  let altNote6 = ''
  let altNote7 = ''
  let altNote8 = ''
  let altNote9 = ''
  let altNote10 = ''

  let note1 = ''
  let note2 = ''
  let note3 = ''
  let note4 = ''
  let note5 = ''
  let note6 = ''
  let note7 = ''
  let note8 = ''
  let note9 = ''
  let note10 = ''

  Font.register({
    family: 'Bad Script',
    src: 'https://fonts.gstatic.com/s/badscript/v5/rL_b2ND61EQmMOJ8CRr1fi3USBnSvpkopQaUR-2r7iU.ttf',
    fontWeight: 700,
  })

  Font.register({
    family: 'Satisfy',
    src: 'https://fonts.gstatic.com/s/satisfy/v6/PRlyepkd-JCGHiN8e9WV2w.ttf',
    fontWeight: 700,
  })

  Font.register({
    family: 'Lato',
    src: 'https://fonts.gstatic.com/s/lato/v11/h7rISIcQapZBpei-sXwIwg.ttf',
    fontWeight: 400,
  })

  Font.register({
    family: 'Lato1',
    src: 'https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf',
    fontWeight: 700,
  })

  if (alertNotes) {
    for (let inn = 0; inn < alertNotes.length; inn++) {
      const x = alertNotes[inn]
      if (x.location === 'TRANSMISSION') {
        transmissionCount++

        if (transmissionCount === 1) {
          note1 = x.note
        }
        if (transmissionCount === 2) {
          note2 = x.note
        }
        if (transmissionCount === 3) {
          note3 = x.note
        }
        if (transmissionCount === 4) {
          note4 = x.note
        }
        if (transmissionCount === 5) {
          note5 = x.note
        }
        if (transmissionCount === 6) {
          note6 = x.note
        }
        if (transmissionCount === 7) {
          note7 = x.note
        }
        if (transmissionCount === 8) {
          note8 = x.note
        }
        if (transmissionCount === 9) {
          note9 = x.note
        }
        if (transmissionCount === 10) {
          note10 = x.note
        }
      } else if (x.location === 'BATTERY_STATUS') {
        batteryNoteCount++

        if (batteryNoteCount === 1) {
          batNote1 = x.note
        }
        if (batteryNoteCount === 2) {
          batNote2 = x.note
        }
        if (batteryNoteCount === 3) {
          batNote3 = x.note
        }
        if (batteryNoteCount === 4) {
          batNote4 = x.note
        }
        if (batteryNoteCount === 5) {
          batNote5 = x.note
        }
      } else {
        moniteryValuesNoteCount++

        if (moniteryValuesNoteCount === 1) {
          monitNote1 = x.note
        }
        if (moniteryValuesNoteCount === 2) {
          monitNote2 = x.note
        }
        if (moniteryValuesNoteCount === 3) {
          monitNote3 = x.note
        }
        if (moniteryValuesNoteCount === 4) {
          monitNote4 = x.note
        }
        if (moniteryValuesNoteCount === 5) {
          monitNote5 = x.note
        }
      }
    }
  }

  // starting of normal alert card notes
  if (transmissionReportAlerts) {
    for (let ind = 0; ind < transmissionReportAlerts.length; ind++) {
      const y = transmissionReportAlerts[ind]

      //  if (y.title !== 'Episodes') {
      const z = y.notes
      alertNoteCount++
      if (alertNoteCount === 1) {
        alertName1 = y.title
        if (y.notes) {
          for (let v = 0; v < z.length; v++) {
            transmissionReportAlertsnote++

            if (transmissionReportAlertsnote === 1) {
              altNote1 = z[v].note
            }
            if (transmissionReportAlertsnote === 2) {
              altNote2 = z[v].note
            }
            if (transmissionReportAlertsnote === 3) {
              altNote3 = z[v].note
            }
            if (transmissionReportAlertsnote === 4) {
              altNote4 = z[v].note
            }
            if (transmissionReportAlertsnote === 5) {
              altNote5 = z[v].note
            }
          }
        }
      } else if (alertNoteCount === 2) {
        alertName2 = y.title
        if (y.notes) {
          for (let w = 0; w < z.length; w++) {
            transmissionReportAlertsnoteTwo++

            if (transmissionReportAlertsnoteTwo === 1) {
              altNote6 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 2) {
              altNote7 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 3) {
              altNote8 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 4) {
              altNote9 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 5) {
              altNote10 = z[w].note
            }
          }
        }
      } else if (alertNoteCount === 3) {
        alertName3 = y.title
        if (y.notes) {
          for (let n = 0; n < z.length; n++) {
            episodeAlertsnote++

            if (episodeAlertsnote === 1) {
              epiCard1 = z[n].note
            }
            if (episodeAlertsnote === 2) {
              epiCard2 = z[n].note
            }
            if (episodeAlertsnote === 3) {
              epiCard3 = z[n].note
            }
            if (episodeAlertsnote === 4) {
              epiCard4 = z[n].note
            }
            if (episodeAlertsnote === 5) {
              epiCard5 = z[n].note
            }
          }
        }
      }
    }
  }

  const note1bullet = ''
  const note2bullet = ''
  const note3bullet = ''
  const note4bullet = ''
  const note5bullet = ''
  const note6bullet = ''
  const note7bullet = ''
  const note8bullet = ''
  const note9bullet = ''
  const note10bullet = ''

  let notesname1 = ''
  let notesname2 = ''
  let notesname3 = ''

  if (epiCard1) {
    notesname3 = 'Notes:'
  }
  if (altNote6) {
    notesname2 = 'Notes:'
  }
  if (altNote1) {
    notesname1 = 'Notes:'
  }

  const reportName = transmissionReport?.color
  const reportNamew = reportName as keyof typeof reportTypeMap
  const reportTypeMap = {
    YELLOW: transmissionReport?.flagHeartFailure
      ? 'Requires Review: Heart Failure report'
      : 'Requires Review',
    GREEN: transmissionReport?.flagHeartFailure
      ? 'Routine: Heart Failure report'
      : 'Routine Report',
    RED: transmissionReport?.flagHeartFailure
      ? 'Urgent: Heart Failure report'
      : 'Urgent Report',
  }

  const reportType = reportTypeMap[reportNamew]

  function ReportShow() {
    if (reportType !== '') {
      return <Text style={styles.roundbutton}>{reportType}</Text>
    } else {
      return <span></span>
    }
  }

  const defaultValues: Record<string, any> = useMemo(
    () => getDefaultTransmissionValuesForPatient(transmissionReport?.patient),
    [transmissionReport?.patient],
  )
  let oacValue
  if (defaultValues.oac?.label!) {
    oacValue = defaultValues.oac.label!
  }

  const cardoneNotes = []
  cardoneNotes.push(epiCard1, epiCard2, epiCard3, epiCard4, epiCard5)

  const cardTwoNotes = []
  cardTwoNotes.push(altNote1, altNote2, altNote3, altNote4, altNote5)

  const cardThreeNotes = []
  cardThreeNotes.push(altNote6, altNote7, altNote8, altNote9, altNote10)

  const monitNotes = []
  monitNotes.push(monitNote1, monitNote2, monitNote3, monitNote4, monitNote5)

  const battNotes = []

  battNotes.push(batNote1, batNote2, batNote3, batNote4, batNote5)

  function faxNumber() {
    if (clinicfax) {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '5px',
          }}
        >
          <Text style={{ fontSize: '9pt', color: '#213147' }}>{faxx}</Text>
          <Text style={{ fontSize: '9pt', color: '#455468' }}>{clinicfax}</Text>
        </View>
      )
    }
  }

  function notesname1Function() {
    if (notesname1 !== '') {
      return <Text style={styles.subheading}>{notesname1}</Text>
    }
  }

  function alertName1Function() {
    if (alertName1 !== '') {
      return <Text style={styles.heading}>{alertName1}</Text>
    }
  }

  function notesname2Function() {
    if (notesname2 !== '') {
      return <Text style={styles.subheading}>{notesname2}</Text>
    }
  }

  function alertName2Function() {
    if (alertName2 !== '') {
      return <Text style={styles.heading}>{alertName2}</Text>
    }
  }

  function notesname3Function() {
    if (notesname3 !== '') {
      return <Text style={styles.subheading}>{notesname3}</Text>
    }
  }

  function alertName3Function() {
    if (alertName3 !== '') {
      return <Text style={styles.heading}>{alertName3}</Text>
    }
  }

  function DeviceShow() {
    if (devvv !== 'no_device_type' && devvv !== '') {
      return <Text style={styles.squarebutton}>{devvv}</Text>
    } else {
      return <span></span>
    }
  }

  function commFunction() {
    if (commaccount === 'secondSign') {
      return (
        <View
          style={{
            margin: '12px 20px 0px 20px',
            padding: '12px',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            border: '1px solid #6C7789',
          }}
        >
          <Text style={styles.headingInter}>
            Provider/Clinician Interpretation
          </Text>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{comm}</Text>
          </View>
          {/* <Text
        style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
      >
        {comm}
      </Text> */}
        </View>
      )
    }
  }

  const myFunction = cardoneNotes.map((nott, index) => {
    if (nott !== '') {
      return (
        <div key={nott + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {nott}
          </Text>
        </div>
      )
    } else {
      return <span key={nott + '_' + index} />
    }
  })

  const myFunctionTwo = cardTwoNotes.map((nottr, index) => {
    if (nottr !== '') {
      return (
        <div key={nottr + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {nottr}
          </Text>
        </div>
      )
    } else {
      return <span key={nottr + '_' + index} />
    }
  })

  const myFunctionThree = cardThreeNotes.map((notty, index) => {
    if (notty !== '') {
      return (
        <div key={notty + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {notty}
          </Text>
        </div>
      )
    } else {
      return <span key={notty + '_' + index} />
    }
  })

  const useEpisodeUrls = []
  useEpisodeUrls.push(urlImgEpisode1, urlImgEpisode2, urlImgEpisode3)

  if (alertName3 === 'Presenting' || alertName3 === 'Episodes') {
    useEpisodeUrls.push(urlegm)
  }

  const getEpisodeUrls = []

  const useUrls = []
  useUrls.push(urlImg3, urlImg2, urlImg1)
  if (alertName1 === 'Presenting' || alertName1 === 'Episodes') {
    useUrls.push(urlegm)
  }

  const useUrls2 = []
  useUrls2.push(urlImg6, urlImg5, urlImg4)
  if (alertName2 === 'Presenting' || alertName2 === 'Episodes') {
    useUrls2.push(urlegm)
  }

  for (let tc = 0; tc < useEpisodeUrls.length; tc++) {
    if (
      typeof useEpisodeUrls[tc] !== 'undefined' &&
      useEpisodeUrls[tc] !== ''
    ) {
      getEpisodeUrls.push(useEpisodeUrls[tc])
    }
  }

  const getUrls = []

  if (useUrls) {
    for (let ttt = 0; ttt < useUrls.length; ttt++) {
      if (typeof useUrls[ttt] !== 'undefined') {
        getUrls.push(useUrls[ttt])
      }
    }
  }

  const getUrls2 = []

  if (useUrls2) {
    for (let tt = 0; tt < useUrls2.length; tt++) {
      if (typeof useUrls2[tt] !== 'undefined') {
        getUrls2.push(useUrls2[tt])
      }
    }
  }

  const cardoneimages = getUrls.map(imagg => {
    return (
      <div key={imagg}>
        <View>
          <Image
            style={{
              // marginVertical: 8,
              // marginHorizontal: 6,
              marginTop: '4px',
            }}
            source={imagg}
          />
        </View>
      </div>
    )
  })

  const cardtwoimages = getUrls2.map(imgg => {
    return (
      <div key={imgg}>
        <View>
          <Image
            style={{
              // marginVertical: 8,
              // marginHorizontal: 6,
              marginTop: '4px',
            }}
            source={imgg}
          />
        </View>
      </div>
    )
  })

  const epiImages = getEpisodeUrls.map(immg => {
    return (
      <div key={immg}>
        <View>
          <Image
            style={{
              // marginVertical: 8,
              // marginHorizontal: 6,
              marginTop: '4px',
            }}
            source={immg}
          />
        </View>
      </div>
    )
  })

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    sign: {
      fontSize: '17pt',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Satisfy',
    },

    flex: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 0,
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    desc: {
      fontSize: '9pt',
      color: '#6C7789',
      marginBottom: '6px',
    },
    details: {
      fontSize: '9pt',
      color: '#213147',
      marginBottom: '2px',
    },
    detailsHeads: {
      fontSize: '11pt',
      fontWeight: 'bold',
      color: '#213147',
      marginBottom: '2px',
    },
    headingInter: {
      fontSize: '13pt',
      marginTop: '5px',
      marginBottom: '5px',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    notee: {
      fontSize: '10pt',
      color: 'black',
      paddingBottom: 5,
    },
    hline: {
      border: '1px solid gray',
      margin: '10px 0 20px 0',
    },
    roundbutton: {
      borderRadius: '50px',
      padding: '4px 7px 2px 8px',
      border: '1px solid #6C7789',
      marginRight: '12px',
      backgroundColor: '#EFF2F6',
      fontSize: '9pt',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    squarebutton: {
      padding: '4px 7px 2px 8px',
      border: '1px solid #6C7789',
      marginRight: '12px',
      backgroundColor: '#EFF2F6',
      fontSize: '9pt',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    heading: {
      fontSize: '13pt',
      color: '#213147',
      marginBottom: '5px',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    subheading: {
      fontSize: '11pt',
      color: '#213147',
      marginBottom: '3px',
    },
    tableheader: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '33%',
      padding: '4px 0',
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    tablebody: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '33%',
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytableheader: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '16.6%',
      padding: '4px 0',
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytableheader1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '25%',
      padding: '4px 0',
      paddingLeft: 10,
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytablebody: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '16.6%',
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytablebody1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '25%',
      paddingLeft: 20,
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    pageNumbers: {
      position: 'absolute',
      bottom: 10,
      left: 20,
      right: 0,
      textAlign: 'left',
      fontSize: '9pt',
      color: '#213147',
    },
    pageNumbers1: {
      position: 'absolute',
      bottom: 0,
      left: 20,
      right: 20,
      textAlign: 'right',
      fontSize: '9pt',
      color: '#213147',
    },
  })

  return (
    <Document>
      <Page
        style={{
          fontFamily: 'Lato',
          paddingBottom: '20',
        }}
        size="A4"
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingRight: 20,
            paddingLeft: 20,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {/* <Text style={styles.roundbutton}>{reportType}</Text>
            <Text style={styles.squarebutton}>{devvv}</Text> */}
            {ReportShow()}
            {DeviceShow()}
          </View>
          <View>
            <Image
              style={{ width: '72px', height: '16px', marginRight: '2px' }}
              src={Rhythm_logo}
            />
          </View>
        </View>

        <View
          style={{ border: '1px solid #E0E5EB', margin: '7px 20px 8px 20px' }}
        ></View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 20px',
          }}
        >
          <View
            style={{
              flex: '0 0 35%',
              maxWidth: '35%',
              paddingRight: '10px',
            }}
          >
            {/* <Text style={styles.details}>Left Side</Text> */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ paddingRight: '10px' }}>
                <Image style={{ width: '25px' }} src={patient} />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: '9pt',
                    color: '#6C7789',
                    marginBottom: '2px',
                  }}
                >
                  Patient
                </Text>
                <Text style={styles.detailsHeads}>
                  {`${givenName} ${familyName}`}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flex: '0 0 65%',
              maxWidth: '65%',
              paddingRight: '10px',
              marginLeft: '15px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>MRN:</Text>
                <Text style={styles.desc}>
                  {defaultValues.mrn2 ? defaultValues.mrn2 : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Diagnosis:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {diagnosisOption ? diagnosisOption : '-'}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Referring Provider</Text>
                <Text style={styles.desc}>
                  {capitalizeFirstCharacter(referringProvider(defaultValues)) ??
                    '-'}{' '}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>DOB:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {defaultValues.birthDate ? defaultValues.birthDate : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>OAC:</Text>
                <Text style={styles.desc}>{oacValue ? oacValue : '-'}</Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Following Practitioner:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {practionerForPdf ? practionerForPdf : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                }}
              >
                <Text style={styles.details}>Reasons for Monitoring:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {monitorReason ? monitorReason : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{ border: '1px solid #E0E5EB', margin: '2px 20px 8px 20px' }}
        ></View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 20px',
          }}
        >
          <View
            style={{
              flex: '0 0 35%',
              maxWidth: '35%',
              paddingRight: '10px',
            }}
          >
            {/* <Text style={styles.details}>Left Side</Text> */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ paddingRight: '10px' }}>
                <Image style={{ width: '25px' }} src={device} />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: '9pt',
                    color: '#6C7789',
                    marginBottom: '2px',
                  }}
                >
                  Device Info
                </Text>
                <Text style={styles.detailsHeads}>{deviceModelNumber}</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flex: '0 0 65%',
              maxWidth: '65%',
              paddingRight: '10px',
              marginLeft: '15px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>Manufacturer</Text>

                <Text style={styles.desc}>{vendorName ? vendorName : '-'}</Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Serial No.</Text>
                <Text style={styles.desc}>
                  {deviceSerialNumber ? deviceSerialNumber : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>DOI</Text>
                <Text style={styles.desc}>
                  {formatUtcDate(deviceImplantDate)
                    ? formatUtcDate(deviceImplantDate)
                    : '-'}{' '}
                </Text>
              </View>

              {/* <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}></Text>
                <Text style={styles.desc}></Text>
              </View> */}

              {/* <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>Serial No.</Text>
                <Text style={styles.desc}>
                  {!!deviceSerialNumber ? deviceSerialNumber : '-'}
                </Text>
              </View> */}
              {/* <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}></Text>
                <Text style={styles.desc}></Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}></Text>
                <Text style={styles.desc}></Text>
              </View> */}
            </View>
          </View>
        </View>

        {/* {myTransmissionFunction} */}
        <View
          style={{
            margin: '12px 20px 0px 20px',
            padding: '12px',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            border: '1px solid #6C7789',
          }}
        >
          <Text
            style={{
              fontSize: '13pt',
              color: '#213147',
              marginBottom: '0px',
              fontWeight: 700,
              fontFamily: 'Lato1',
            }}
          >
            Technician Interpretation
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '5px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note1bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note1}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note2bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note2}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note3bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note3}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note4bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note4}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note5bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note5}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note6bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note6}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note7bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note7}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note8bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note8}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note9bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note9}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note10bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note10}</Text>
          </View>
        </View>
        {commFunction()}
        <View style={{ margin: '5px 20px 0px 20px' }}>
          {alertName3Function()}
          {notesname3Function()}
          {myFunction}
        </View>
        <View style={{ marginLeft: 20, marginRight: 20, maxWidth: '100%' }}>
          {epiImages}
        </View>

        <View style={{ margin: '5px 20px 0px 20px' }}>
          {alertName2Function()}
          {notesname2Function()}
          {myFunctionThree}
        </View>
        <View style={{ marginLeft: 20, marginRight: 20, maxWidth: '100%' }}>
          {cardtwoimages.reverse()}
        </View>

        <View style={{ margin: '5px 20px 0px 20px' }}>
          {alertName1Function()}
          {notesname1Function()}
          {myFunctionTwo}
        </View>
        <View style={{ marginLeft: 20, marginRight: 20, maxWidth: '100%' }}>
          {cardoneimages.reverse()}
        </View>

        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#EFF2F6',
            padding: '15px 20px',
            marginTop: '10px',
          }}
        >
          <View
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingRight: '10px',
              }}
            >
              <Text style={styles.subheading}>{clinicname}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                  marginTop: '5px',
                }}
              >
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  {tele}
                </Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {clinicphoneNumber}
                </Text>
              </View>
              {faxNumber()}
              <Text style={{ fontSize: '9pt', color: '#455468' }}>
                {line1}
                {line2}
              </Text>
              <Text style={{ fontSize: '9pt', color: '#455468' }}>
                {city}
                {state}
                {postalCode}
              </Text>
            </View>
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View>
                <Text
                  wrap={false}
                  style={styles.sign}
                >{`${sign1Given} ${sign1Family}${
                  sign1Credentials ? ', ' + sign1Credentials : ''
                }`}</Text>
              </View>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#213147',
                  marginBottom: '3px',
                }}
              >
                {sign1Text}
              </Text>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#455468',
                  marginBottom: '5px',
                }}
              >
                {`${sign1Given} ${sign1Family}`}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>Date:</Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {sign1Date}
                </Text>
              </View>
            </View>

            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View>
                <Text
                  wrap={false}
                  style={styles.sign}
                >{`${sign2Given} ${sign2Family}${
                  sign2Credentials ? ', ' + sign2Credentials : ''
                }`}</Text>
              </View>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#213147',
                  marginBottom: '3px',
                }}
              >
                {sign2Text}
              </Text>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#455468',
                  marginBottom: '5px',
                }}
              >
                {`${sign2Given} ${sign2Family}`}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>Date:</Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {sign2Date}
                </Text>
              </View>
            </View>
            <View
              style={{ flex: '0 0 100%', maxWidth: '100%', marginTop: '35px' }}
            ></View>
          </View>
        </View>

        {showEditedByInfoOnPdf ? (
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#EFF2F6',
              padding: '15px 20px',
              fontSize: '11pt',
            }}
          >
            <Text>
              Edited by {editedUser} on {editedDateUtc} at{' '}
              {editedTimeWithTimezone}
            </Text>
          </View>
        ) : (
          <View></View>
        )}
        <View style={styles.pageNumbers} fixed>
          <Text>
            Patient:{' '}
            {`${givenName} ${familyName}   DOB: ${
              defaultValues.birthDate ? defaultValues.birthDate : '-'
            }`}
          </Text>
          <Text
            style={styles.pageNumbers1}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages} `
            }
          />
        </View>
      </Page>
    </Document>
  )
}

export default CreatingILRPDF
