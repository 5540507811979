import React, { useState } from 'react'

import {
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Toast,
  useToast,
} from '@rhythm/components'

import { useApplyReportTemplate } from '../../../../../features/reportTemplate/api/applyReportTemplate'
import { useGetReportTemplatesByTxId } from '../../../../../features/reportTemplate/api/getAllReportTemplatesByTxId'
import { useRemoveReportTemplate } from '../../../../../features/reportTemplate/api/removeReportTemplate'
import { useReplaceReportTemplate } from '../../../../../features/reportTemplate/api/replaceReportTemplate'
import { useTransmissionReportContext } from '../../../../../features/transmissionReports'
import { ReportTemplate, TemplateObject } from '../../../../../lib/api'
import ConfirmationModal from '../ConfirmationModal'

export const TemplateFieldMappings: Record<string, string> = {
  BATTERY_STATUS: 'Battery Status',
  MONITORED_VALUES: 'Monitored Values',
  ALERT_PRESENTING: 'Presenting Alert Card',
  TRANSMISSION: 'Technician Interpretation',
  IS_HEART_FAILURE: 'Report Name & Billing Codes',
}

const TemplateReportDropdown = ({
  dropDownLabel,
}: {
  dropDownLabel: string
}): React.ReactElement => {
  const toast = useToast()
  const {
    transmissionReport,
    isRefetching: isReportLoading,
    setCurrentTemplateType,
  } = useTransmissionReportContext()

  const { data: reportTemplates, isLoading: reportTemplatesLoading } =
    useGetReportTemplatesByTxId({
      transmissionReportId: transmissionReport?.id,
      deviceType: transmissionReport?.device?.deviceType ?? '',
      vendor:
        (transmissionReport?.deviceVendor?.vendorName ?? '')
          .replace(' ', '_')
          .toLowerCase() ?? '',
    })
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReportTemplate | null>(null)
  const showToast = (message: any) =>
    toast({
      position: 'bottom-right',
      render: ({ onClose }) => (
        <Toast
          variant="success"
          onClose={onClose}
          style={{
            position: 'relative',
            bottom: '100px',
            border: '1px solid green',
          }}
        >
          {message}
        </Toast>
      ),
    })

  const fetchToastHtml = (
    templateObjects: TemplateObject[],
    action: string,
  ) => {
    const elementArr: JSX.Element[] = []
    const templateFields: Set<string> = new Set()
    templateObjects.forEach(templateObject => {
      for (const val in templateObject) {
        templateFields.add(val)
      }
    })
    for (const val of Array.from(templateFields)) {
      const key = TemplateFieldMappings[val]
      elementArr.push(<p>• {key}</p>)
    }
    return (
      <div>
        <p>Template {action} Successfully.</p>
        <p>Following Fields have been updated</p>
        <br />
        {elementArr}
      </div>
    )
  }

  const applyReportMutation = useApplyReportTemplate(
    (templateObject: TemplateObject) => {
      const toastHtml = fetchToastHtml([templateObject], 'Applied')
      return showToast(toastHtml)
    },
  )
  const replaceReportMutation = useReplaceReportTemplate(
    (removed: TemplateObject, applied: TemplateObject) => {
      const toastHtml = fetchToastHtml([removed, applied], 'Replaced')
      return showToast(toastHtml)
    },
  )
  const removeReportMutation = useRemoveReportTemplate(
    (templateObject: TemplateObject) => {
      const toastHtml = fetchToastHtml([templateObject], 'Removed')
      return showToast(toastHtml)
    },
  )

  const onTemplateChangeConfirm = (template: ReportTemplate) => {
    // if there are no templates applied and selected a template, apply the template
    if (!transmissionReport?.activeTemplate?.id) {
      applyReportMutation.mutateAsync({
        applyOrReplaceTemplateRequest: {
          reportId: transmissionReport?.id as number,
          templateId: template.id,
        },
      })
      return
    }

    // if there is already a template, and current template is same as the selected template, revert the template
    if (transmissionReport?.activeTemplate?.id === template.id) {
      removeReportMutation.mutateAsync({
        removeTemplateRequest: {
          reportId: transmissionReport?.id as number,
        },
      })
      return
    } else if (
      transmissionReport?.activeTemplate?.id &&
      transmissionReport?.activeTemplate?.id !== template.id
    ) {
      // if there is already a template, and current template is different from the selected template, replace the template
      replaceReportMutation.mutateAsync({
        applyOrReplaceTemplateRequest: {
          reportId: transmissionReport?.id as number,
          templateId: template.id,
        },
      })
      return
    }
  }

  const onMenuItemClick = (template: ReportTemplate) => {
    if (transmissionReport?.activeTemplate !== null) {
      setSelectedTemplate(template)
    } else {
      onTemplateChangeConfirm(template)
    }
  }

  return (
    <Flex
      borderRight={2}
      h={'full'}
      key={'filter'}
      px="2"
      minW={185}
      _last={{ borderBottom: 'none' }}
      _hover={{ cursor: 'pointer' }}
      boxShadow={'none'}
    >
      <Text
        color={'#516880'}
        fontSize={'14px'}
        fontWeight={600}
        fontFamily={'Lato'}
        textAlign={'left'}
        mr={'12px'}
        alignContent={'center'}
      >
        {dropDownLabel}:{' '}
      </Text>
      <HStack>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton as={Link}>
                <HStack>
                  {reportTemplatesLoading ||
                  applyReportMutation.isLoading ||
                  replaceReportMutation.isLoading ||
                  removeReportMutation.isLoading ||
                  isReportLoading ? (
                    <Spinner size="sm" color="primary.600" />
                  ) : (
                    <Text
                      minW={12}
                      color={'#0E6DAD'}
                      fontSize={'14px'}
                      fontWeight={600}
                    >
                      {transmissionReport?.activeTemplate?.name ??
                        'Not Selected'}
                    </Text>
                  )}
                  <Icon
                    icon={!isOpen ? 'drop-down' : 'drop-up'}
                    boxSize={'sm'}
                    color="#0E6DAD"
                    alignContent={'flex-end'}
                    float={'right'}
                    paddingLeft={'2px'}
                  />
                </HStack>
              </MenuButton>
              <MenuList marginLeft={'-80px'}>
                {reportTemplates?.map(template => (
                  <MenuItem
                    minW={2}
                    key={`${template.id}-dropdown-options`}
                    onClick={() => {
                      onMenuItemClick(template)
                    }}
                  >
                    <HStack
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'full'}
                    >
                      <Text>{template.name}</Text>
                      {transmissionReport?.activeTemplate?.id ===
                        template.id && (
                        <Icon
                          icon="check"
                          boxSize={'sm'}
                          color="#0E6DAD"
                          float={'right'}
                        />
                      )}
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </HStack>
      {selectedTemplate && (
        <ConfirmationModal
          isOpen={!!selectedTemplate}
          isLoading={false}
          onClose={() => setSelectedTemplate(null)}
          onConfirm={() => {
            onTemplateChangeConfirm(selectedTemplate)
            setSelectedTemplate(null)
            setCurrentTemplateType(selectedTemplate.id)
          }}
          title={'Are you sure you want to proceed?'}
          description={
            'The notes added from this template will be removed. Any user entered notes will be saved.'
          }
        />
      )}
    </Flex>
  )
}

export default TemplateReportDropdown
