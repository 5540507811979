import { useCallback, useEffect, useRef, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'

import { Flex, Spinner, Toast, useToast } from '@rhythm/components'
import moment from 'moment'

import { useApiContext } from '../../../context/ApiContext'
import { useGetAuthorizedPractitioners } from '../../../features/patients'
import {
  getTReportById,
  useGetTransmissionReportById,
  useGetTransmissionReportsCountByColor,
  useGetVendorTransmissionReports,
  useSignTransmissionReport,
  useTransmissionReportContext,
  useTransmissionReportsListContext,
  useVtLeadVendorList,
  useVtVendorList,
} from '../../../features/transmissionReports'
import useCurrentUser from '../../../hooks/useCurrentUser'
import {
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportSignature,
  User,
} from '../../../lib/api'
import routes from '../../../routes/config'
import { TrackableEvents } from '../../../types'
import { SIDEBAR_OPEN_WIDTH } from '../../../utils/constants'
import { getNextTransmission } from '../../../utils/getNextTransmissionIndex'
import {
  getDateTimeWithTimezone,
  moveToNextColor,
} from '../../../utils/transmissionReportUtil'
import { PrimaryCtaText } from '../PatientProfilePage/components/DeviceDetailsWithTransmissionSchedule/constants'

import SignAllRoutineModal from './components/SignAllRoutineModal'
import SignReportModal from './components/SignReportModal'
import TransmissionActionBar from './components/TransmissionActionBar'
import TransmissionReportDetails from './components/TransmissionReportDetails'
import TransmissionReviewSideBar from './components/TransmissionReviewSideBar'
import ClinicalNotesSideBar from './components/TransmissionReviewSideBar/ClinicalNotesSidebar'
import TransmissionReviewStatusBar from './components/TransmissionReviewStatusBar'
import ViewReportHisotryModal from './components/ViewReportModal/ViewReportHisotryModal'
import ViewVendorReportCombinedPDFModal from './components/ViewReportModal/ViewVendorReportCombinedPDFModal'

export type TransmissionReportPageLocationState = {
  editReportId?: string
  signAllRoutineModalIsOpen?: boolean
  defaultExpanded?: TransmissionReportDtoColorEnum
  expandedIndexOverride?: number
  isEditing?: boolean
  currentReport?: TransmissionReportDto
  triageColorChangedTransmissionId?: string
}

export interface PractitionerOption {
  label: string
  value: string | null
}

const TransmissionReportPage = () => {
  const { currentUser } = useCurrentUser()
  const history = useHistory()
  const location = useLocation<TransmissionReportPageLocationState>()
  const isReviewMode = !!matchPath(location.pathname, {
    path: routes.physician.transmissionReview,
    exact: true,
  })

  const Api = useApiContext()
  const [viewVendorReportModalIsOpen, setViewVendorReportModalIsOpen] =
    useState(false)

  const [signReportModalIsOpen, setSignReportModalIsOpen] = useState(false)

  const [reportHistoryModalIsOpen, setReportHistoryModalIsOpen] =
    useState(false)

  const {
    data: transmissionReportsData,
    loadedTransmissionReports,
    setLoadedTransmissionReports,
  } = useTransmissionReportsListContext()

  const [reviewSideBarIsOpen, setReviewSideBarIsOpen] = useState(
    isReviewMode &&
      transmissionReportsData &&
      transmissionReportsData?.transmissionReports.length > 0,
  )
  const [primaryCtaText, setPrimaryCtaText] = useState<PrimaryCtaText>(
    (sessionStorage.getItem('primaryCtaText') as PrimaryCtaText) ??
      PrimaryCtaText.SignReport,
  )

  const [signReportWorkflowStartTime, setSignReportWorkflowStartTime] =
    useState<Date | undefined>()

  const {
    currentTransmission,
    setCurrentTransmission,
    clinicalNotesSideBarIsOpen,
    setClinicalNotesSideBarIsOpen,
  } = useTransmissionReportContext()

  const [editReportId, setEditReportId] = useState(
    location.state?.editReportId
      ? Number(location.state?.editReportId)
      : currentTransmission?.id,
  )

  useEffect(() => {
    if (location.state?.editReportId) {
      setEditReportId(Number(location.state?.editReportId))
    } else {
      setEditReportId(currentTransmission?.id)
    }
  }, [currentTransmission?.id, location.state?.editReportId])

  const { data: transmissionReport, isLoading: isReportLoading } =
    useGetTransmissionReportById(
      {
        transmissionReportId: editReportId!,
      },
      {
        enabled: !!editReportId,
      },
    )
  const patientId = transmissionReport?.patient?.id ?? ''

  const editReportSignatures =
    currentTransmission?.signatures[
      currentTransmission?.signatures?.length - 1
    ] ?? undefined
  const editReportSignCreatedAt = editReportSignatures?.createdAt

  const typeMappings = {
    remote: 'Transmission Report',
    'in-clinic': 'In-clinic Report',
    manual: 'Manual Report',
  }

  let reportType = transmissionReport?.transmissionType ?? ''

  if (reportType) {
    reportType =
      typeMappings[reportType as keyof typeof typeMappings] || reportType
  }
  const {
    mutateAsync: signReport,
    isLoading: signReportIsLoading,
    isSuccess: signReportIsSuccess,
    isError: signReportIsError,
  } = useSignTransmissionReport()

  const { data: countData } = useGetTransmissionReportsCountByColor()

  const { mutate: getVendorList, data: vendorList } = useVtVendorList()
  const { mutate: getLeadVendorList, data: leadVendorList } =
    useVtLeadVendorList()

  useEffect(() => {
    if (vendorList === undefined) {
      getVendorList({ vtVendorListParams: { limit: 100 } })
    }
  }, [getVendorList, vendorList])

  useEffect(() => {
    if (leadVendorList === undefined) {
      getLeadVendorList({ vtLeadVendorListParams: { limit: 100 } })
    }
  }, [getLeadVendorList, leadVendorList])

  const toast = useToast()
  const showSignReportSuccessToast = useCallback(() => {
    const MESSAGES = [
      'Signed! Keep up the great work!',
      'Signed! You are making a difference!',
      'Signed! Great job!',
    ]
    const messageIndex = Math.floor(Math.random() * MESSAGES.length)
    toast({
      position: 'top',
      render: ({ onClose }) => (
        <Toast variant="success" onClose={onClose}>
          {MESSAGES[messageIndex]}
        </Toast>
      ),
    })
  }, [toast])

  const showSignReportErrorToast = useCallback(() => {
    toast({
      position: 'top',
      render: ({ onClose }) => (
        <Toast variant="error" onClose={onClose}>
          Sign Report Failed
        </Toast>
      ),
    })
  }, [toast])

  const [transmissionId, setTransmissionId] = useState(0)

  const [showToast, setShowToast] = useState(true)
  useEffect(() => {
    window.analytics.identify(currentUser?.externalId, {
      email: currentUser?.email,
    })
  }, [currentUser])

  useEffect(() => {
    if (transmissionReport?.id) {
      setTransmissionId(transmissionReport?.id)
      setSignReportWorkflowStartTime(moment().toDate())
    }
  }, [transmissionReport?.id])

  useEffect(() => {
    if (transmissionReport?.id) {
      window.analytics.track(TrackableEvents.ViewedNotSigned, {
        vendorName: transmissionReport?.transmissionVendor?.vendorName,
        deviceType: transmissionReport?.device?.deviceType,
        role: currentUser?.role,
        userId: currentUser?.externalId,
        email: currentUser?.email,
        transmissionReportId: transmissionReport?.id,
        smartNarrativeStatus: transmissionReport?.smartNarrativeStatus,
      })
    }
  }, [transmissionReport, currentUser])

  const {
    data: vendorTransmissionReportsList,
    isLoading: isVendorTransmissionReportsLoading,
  } = useGetVendorTransmissionReports({
    transmissionReportId: transmissionId,
  })

  const reportsList = vendorTransmissionReportsList?.filter(
    (e: any) =>
      !e.name.includes(transmissionReport?.patient?.rhythmId) && e.pdfUrl,
  )

  const { data: practitioners } = useGetAuthorizedPractitioners({
    patientId,
  })

  const [practitionerOptions, setPractitionerOptions] = useState(
    [] as PractitionerOption[],
  )

  useEffect(() => {
    const newPractitionerOptions = practitioners?.map(
      ({ id, family, given, prefix }: User) => ({
        label: (prefix.length ? `${prefix} ` : '') + `${given} ${family}`,
        value: id,
      }),
    )
    if (newPractitionerOptions?.length)
      setPractitionerOptions(newPractitionerOptions)
  }, [practitioners])

  // PDF creation data  code ends here

  const handleSignReport = async () => {
    const signDate = getDateTimeWithTimezone()
    await signReport({
      transmissionReportId: currentTransmission?.id ?? 0,
      transmissionReportSignDateParams: { signDate: signDate },
    })

    const signReportWorkflowCompletionTime = moment().toDate()
    const signReportWorkflowDuration = moment
      .duration(moment().diff(moment(signReportWorkflowStartTime)))
      .asSeconds()

    window.analytics.track(TrackableEvents.ViewedSigned, {
      startTimeUTC: signReportWorkflowStartTime,
      endTimeUTC: signReportWorkflowCompletionTime,
      durationSeconds: signReportWorkflowDuration,
      color: transmissionReport?.color,
      vendorName: transmissionReport?.transmissionVendor?.vendorName,
      deviceType: transmissionReport?.device?.deviceType,
      role: currentUser?.role,
      userId: currentUser?.externalId,
      email: currentUser?.email,
      transmissionReportId: transmissionReport?.id,
      smartNarrativeStatus: transmissionReport?.smartNarrativeStatus,
    })
  }

  const updateLoadedTransmissionReports = useCallback(() => {
    if (!currentTransmission?.id) return
    const updatedTransmissionReports =
      loadedTransmissionReports?.transmissionReports.filter(
        (t: TransmissionReportDto) => t.id !== currentTransmission?.id,
      )

    setLoadedTransmissionReports({
      transmissionReports: updatedTransmissionReports ?? [],
    })
  }, [
    currentTransmission?.id,
    loadedTransmissionReports?.transmissionReports,
    setLoadedTransmissionReports,
  ])

  useEffect(() => {
    if (!signReportIsSuccess && signReportIsError && showToast) {
      showSignReportErrorToast()
      setShowToast(false)
    }
    if (signReportIsSuccess && !signReportIsError) {
      setSignReportModalIsOpen(false)
      showToast && showSignReportSuccessToast()
      if (primaryCtaText === PrimaryCtaText.SignAndNext) {
        goToNextTransmission()
      } else
        setPrimaryCtaText(
          loadedTransmissionReports &&
            loadedTransmissionReports.transmissionReports.length === 0
            ? PrimaryCtaText.ReturnToDashboard
            : PrimaryCtaText.NextTransmission,
        )
      setShowToast(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signReportIsSuccess,
    signReportIsError,
    showSignReportErrorToast,
    showSignReportSuccessToast,
    setSignReportModalIsOpen,
  ])

  const calculateSideBarWidth = () => {
    let totalSideBarWidth = 68
    if (reviewSideBarIsOpen && !location.state?.isEditing) {
      totalSideBarWidth += SIDEBAR_OPEN_WIDTH
    }
    if (clinicalNotesSideBarIsOpen)
      totalSideBarWidth += SIDEBAR_OPEN_WIDTH + 100
    return totalSideBarWidth
  }

  const getWidth = () => {
    const totalSideBarWidth = calculateSideBarWidth()
    return `calc(100% - ${totalSideBarWidth}px)`
  }

  const goToNextTransmission = useCallback(() => {
    if (
      transmissionReportsData &&
      transmissionReportsData?.transmissionReports?.length === 0 &&
      loadedTransmissionReports?.transmissionReports?.length === 0
    )
      history.push('/physician/dashboard')
    let nextColor = transmissionReport?.color
    if (countData?.counts) {
      const currentColorCount = (
        countData?.counts as Record<TransmissionReportDtoColorEnum, number>
      )[transmissionReport?.color ?? TransmissionReportDtoColorEnum.Gray]
      if (currentColorCount === 0) {
        nextColor = moveToNextColor(currentTransmission?.color)
      }
    }
    const nextTransmission = getNextTransmission({
      location,
      transmissionReports:
        loadedTransmissionReports?.transmissionReports.length > 0
          ? [...loadedTransmissionReports.transmissionReports]
          : transmissionReportsData?.transmissionReports ?? [],
      currentTransmissionId: currentTransmission?.id,
      nextTransmissionColor: nextColor,
    })
    updateLoadedTransmissionReports()
    if (nextTransmission) setCurrentTransmission(nextTransmission)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [
    transmissionReportsData,
    loadedTransmissionReports.transmissionReports,
    history,
    transmissionReport?.color,
    countData?.counts,
    location,
    currentTransmission?.id,
    currentTransmission?.color,
    updateLoadedTransmissionReports,
    setCurrentTransmission,
  ])

  useEffect(() => {
    if (
      transmissionReportsData?.transmissionReports.length === 0 &&
      loadedTransmissionReports?.transmissionReports.length === 0
    )
      history.push('/physician/dashboard')

    const nextTransmission = getNextTransmission({
      location,
      transmissionReports:
        loadedTransmissionReports?.transmissionReports.length > 0
          ? loadedTransmissionReports?.transmissionReports
          : transmissionReportsData?.transmissionReports ?? [],
    })
    if (
      nextTransmission &&
      nextTransmission?.color !== currentTransmission?.color
    )
      if (location.state.triageColorChangedTransmissionId) {
        // Prevent setting the transmission based on the top record of the color in the
        // accordion when the color is changed manually due to triage color change.
        location.state.triageColorChangedTransmissionId = undefined
      } else {
        setCurrentTransmission(nextTransmission)
        //eslint-disable-next-line
      }
  }, [
    history,
    location,
    setCurrentTransmission,
    transmissionReportsData?.transmissionReports,
    loadedTransmissionReports?.transmissionReports,
  ])

  useEffect(() => {
    return () => {
      setLoadedTransmissionReports({ transmissionReports: [] })
    }
  }, [setLoadedTransmissionReports])

  // Create a ref to track if the sidebar has been opened once
  const isSidebarOpenedOnce = useRef(false)

  useEffect(() => {
    if (
      transmissionReportsData?.transmissionReports &&
      transmissionReportsData?.transmissionReports?.length > 0 &&
      !isSidebarOpenedOnce.current
    ) {
      setReviewSideBarIsOpen(true)
      isSidebarOpenedOnce.current = true
    }
    if (!currentTransmission) goToNextTransmission()
  }, [
    currentTransmission,
    goToNextTransmission,
    transmissionReportsData?.transmissionReports,
  ])

  useEffect(() => {
    const ctaText = currentTransmission?.signatures?.find(
      (signature: TransmissionReportSignature) =>
        signature.user?.email === currentUser?.email,
    )
      ? PrimaryCtaText.NextTransmission
      : (sessionStorage.getItem('primaryCtaText') as PrimaryCtaText) ??
        PrimaryCtaText.SignReport
    setPrimaryCtaText(ctaText)
  }, [
    currentTransmission?.id,
    setPrimaryCtaText,
    currentTransmission?.signatures,
    currentUser.email,
    transmissionReportsData?.transmissionReports,
  ])

  const handleOnPrimaryCtaClick = () => {
    setShowToast(true)
    if (location.state?.currentReport) {
      location.state.currentReport = undefined
    }
    switch (primaryCtaText) {
      case PrimaryCtaText.SignReport:
        setSignReportModalIsOpen(true)
        break
      case PrimaryCtaText.SignAndNext:
        handleSignReport()
        break
      case PrimaryCtaText.ReturnToDashboard:
        history.push(routes.physician.dashboard)
        break
      case PrimaryCtaText.NextTransmission:
        goToNextTransmission()
        break
      default:
        break
    }
  }

  const onCloseButton = () => {
    history.push({
      pathname: routes.physician.patientProfile.replace(
        ':id',
        transmissionReport?.patient?.id ?? '',
      ),
    })
  }

  const vendorReportsLoading =
    isVendorTransmissionReportsLoading &&
    transmissionReport?.account.saveCombinedPDF === true

  return (
    <Flex
      flexDirection="column"
      mt="5xl"
      mb={isReviewMode ? '75px' : 0}
      ml={
        reviewSideBarIsOpen && !location.state?.isEditing
          ? `${SIDEBAR_OPEN_WIDTH - 15}px`
          : 0
      }
      width={getWidth()}
      transition="all 0.25s ease"
      transitionProperty="margin, width"
    >
      <TransmissionReportDetails
        vendors={vendorList?.data.vtVendors}
        leadVendors={leadVendorList?.data.vtLeadVendors}
        trReport={location.state?.isEditing ? transmissionReport : undefined}
        practitionerOptions={practitionerOptions}
      />
      {isReviewMode && (
        <>
          <TransmissionReviewStatusBar
            onOpenSideBar={() =>
              !location.state?.isEditing && setReviewSideBarIsOpen(true)
            }
            onReturn={() => {
              history.push(routes.physician.dashboard)
              setLoadedTransmissionReports({
                transmissionReports: [],
              })
            }}
          />
          {currentTransmission && (
            <TransmissionActionBar
              marginLeft={
                reviewSideBarIsOpen && !location.state?.isEditing
                  ? SIDEBAR_OPEN_WIDTH - 15
                  : 0
              }
              sideBarWidth={calculateSideBarWidth()}
              currentUser={currentUser}
              onSendReport={() => {}}
              onPrimaryCtaClick={handleOnPrimaryCtaClick}
              reviewSideBarIsOpen={reviewSideBarIsOpen}
              onViewVendorReport={() => setViewVendorReportModalIsOpen(true)}
              historyReport={() => setReportHistoryModalIsOpen(true)}
              primaryCtaText={primaryCtaText}
              setPrimaryCtaText={setPrimaryCtaText}
              reportsList={reportsList ?? []}
              transmissionReport={transmissionReport}
              isEditing={location.state?.isEditing}
              onClose={onCloseButton}
              isPrimaryCtaLoading={
                isReportLoading ||
                (primaryCtaText === PrimaryCtaText.SignAndNext &&
                  vendorReportsLoading)
              }
            />
          )}
          <TransmissionReviewSideBar
            expandedIndexOverride={
              location.state?.expandedIndexOverride ?? undefined
            }
            selectedTransmissionReportId={currentTransmission?.id ?? 0}
            isOpen={reviewSideBarIsOpen && !location.state?.isEditing}
            onClose={() => setReviewSideBarIsOpen(false)}
            onSelectTransmissionReport={id => {
              const selectedTransmissionReport =
                loadedTransmissionReports?.transmissionReports.find(
                  (t: TransmissionReportDto) => t.id === id,
                )
              if (location.state.editReportId) {
                location.state.editReportId = undefined
              }
              if (selectedTransmissionReport) {
                if (location.state.triageColorChangedTransmissionId) {
                  location.state.triageColorChangedTransmissionId = undefined
                } else {
                  setCurrentTransmission(
                    selectedTransmissionReport as TransmissionReportDto,
                  )
                }
                location.state.defaultExpanded =
                  selectedTransmissionReport?.color
              } else {
                getTReportById(Api, id)
                  .then(data => {
                    if (data) {
                      if (location.state.triageColorChangedTransmissionId) {
                        location.state.triageColorChangedTransmissionId =
                          undefined
                      } else {
                        setCurrentTransmission(data)
                      }
                      location.state.defaultExpanded = data?.color
                    }
                  })
                  .catch(err => console.log(err))
              }
              setTimeout(() => {
                window.scrollTo({ top: 0 })
              })
            }}
          />
          <ClinicalNotesSideBar
            selectedReportPatientId={currentTransmission?.patient?.id ?? ''}
            isOpen={clinicalNotesSideBarIsOpen}
            onClose={() => setClinicalNotesSideBarIsOpen(false)}
          />
          <SignReportModal
            isOpen={signReportModalIsOpen}
            isLoading={signReportIsLoading || vendorReportsLoading}
            onClose={() => setSignReportModalIsOpen(false)}
            onConfirm={handleSignReport}
            reportType={reportType}
          />
          <SignAllRoutineModal
            isOpen={false}
            onClose={() => {}}
            onConfirm={() => {}}
            patients={[]}
          />
          {editReportSignCreatedAt && patientId && transmissionReport && (
            <ViewReportHisotryModal
              isOpen={reportHistoryModalIsOpen}
              onClose={() => setReportHistoryModalIsOpen(false)}
              transmissionReport={transmissionReport}
              transmissionId={editReportId ? editReportId.toString() : ''}
              patientId={patientId}
            />
          )}
          {currentTransmission && (
            <ViewVendorReportCombinedPDFModal
              isOpen={viewVendorReportModalIsOpen}
              isLoading={isVendorTransmissionReportsLoading}
              onClose={() => setViewVendorReportModalIsOpen(false)}
              reportsList={reportsList}
            />
          )}
          {signReportIsLoading && (
            <Spinner
              size="xl"
              color="primary.600"
              thickness="4px"
              position="fixed"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          )}

          {/*<ViewReportModal*/}
          {/*  isOpen={viewVendorReportModalIsOpen}*/}
          {/*  onClose={() => setViewVendorReportModalIsOpen(false)}*/}
          {/*  patient={currentTransmission?.patient ?? undefined}*/}
          {/*  device={currentTransmission?.device}*/}
          {/*/>*/}
        </>
      )}
    </Flex>
  )
}

export default TransmissionReportPage
