import React, { useCallback, useState } from 'react'

import {
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react'
import {
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Text,
  useToken,
} from '@rhythm/components'

import { BlackBatteryIcon } from '../../../../../assets'
import { useDismissTransmissionReport } from '../../../../../features/transmissionReports'
import {
  IsBillableTransmissionReports,
  TransmissionReportDto,
  TransmissionReportType,
} from '../../../../../lib/api'
import { DollarSignIcon } from '../BillingCard/CustomIcons'

interface AccordionItemPanelProps {
  transmissions: TransmissionReportDto[]
  selectedTransmissionId: number
  onSelect: (id: number) => void
  onCheckChange?: (id: number, isChecked: boolean) => void
  selectedTransmissionIds?: number[]
  h?: string
  onScroll?: (e: any) => void
  isLoading?: boolean
  isBillableLoading?: boolean
  isBillableData?: IsBillableTransmissionReports[]
  pageNo: number
}

export const AccordionItemPanel = ({
  transmissions,
  selectedTransmissionId,
  onSelect,
  onCheckChange,
  selectedTransmissionIds,
  onScroll,
  isLoading,
  isBillableData,
  isBillableLoading,
  pageNo,
}: AccordionItemPanelProps): React.ReactElement => {
  const [primary500] = useToken('colors', ['primary.500'])
  const [selectPatient, setSelectPatient] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  const { mutate: dismissReport } = useDismissTransmissionReport()

  const handleDismiss = useCallback(
    id => {
      dismissReport({ transmissionReportId: id })
      setIsOpen(false)
    },
    [dismissReport],
  )

  const handleCheckChange = (id: number, isChecked: boolean) => {
    if (onCheckChange) {
      onCheckChange(id, isChecked)
    }
  }
  const billableReportsMap = new Map<
    number,
    {
      isBillable: boolean
      isEriTracked: boolean
      scheduleTransmissionType: string
    }
  >()
  isBillableData?.forEach(billableReport => {
    billableReportsMap.set(billableReport.transmissionReportId, {
      isBillable: billableReport.isBillable,
      isEriTracked: billableReport.isEriTracked,
      scheduleTransmissionType: billableReport.scheduleTransmissionType ?? '',
    })
  })
  const fetchReportIsBillable = (id: number) => {
    return billableReportsMap.get(id)
  }

  const renderIcon = (
    isBillable: boolean | undefined,
    isEriTracked: boolean | undefined,
    scheduleTransmissionType: string | undefined,
  ) => {
    if (
      isBillable &&
      isEriTracked &&
      scheduleTransmissionType === 'heart_failure'
    ) {
      return (
        <>
          <div
            id="dollarSignSmall"
            style={{
              marginLeft: '10px',
              float: 'right',
              marginTop: '-2px',
            }}
          >
            <BlackBatteryIcon />
          </div>
          <div
            id="dollarSignSmall"
            style={{
              marginLeft: '10px',
              float: 'right',
              marginTop: '-2px',
            }}
          >
            <DollarSignIcon />
          </div>
        </>
      )
    } else if (
      isBillable &&
      isEriTracked &&
      scheduleTransmissionType !== 'heart_failure'
    ) {
      return (
        <div
          id="dollarSignSmall"
          style={{
            marginLeft: '10px',
            float: 'right',
            marginTop: '-2px',
          }}
        >
          {' '}
          <BlackBatteryIcon />
        </div>
      )
    } else if (isBillable) {
      return (
        <div
          id="dollarSignSmall"
          style={{
            marginLeft: '10px',
            float: 'right',
            marginTop: '-2px',
          }}
        >
          {' '}
          <DollarSignIcon />{' '}
        </div>
      )
    } else if (isEriTracked) {
      return (
        <div
          id="dollarSignSmall"
          style={{
            marginLeft: '10px',
            float: 'right',
            marginTop: '-2px',
          }}
        >
          {' '}
          <BlackBatteryIcon />{' '}
        </div>
      )
    } else {
      return null
    }
  }

  const loadingBillableReports = () =>
    isBillableLoading && (
      <div
        style={{ marginBottom: '5px', marginTop: '10px', textAlign: 'center' }}
      >
        <Spinner size={'sm'} color="primary.400" />
        <span style={{ marginLeft: '10px' }}>
          Identifying Billable Reports...
        </span>
      </div>
    )

  return (
    <AccordionPanel
      p="none"
      h={transmissions?.length ? '300px' : '36px'}
      overflow={'auto'}
      onScroll={onScroll ? onScroll : () => {}}
    >
      {transmissions?.length === 0 && !isLoading && (
        <Flex flexDirection="column" flexGrow={1} position="relative">
          <HStack
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="6px 6px"
            gap="4px"
          >
            <Text alignItems="center" marginLeft="12px">
              No Transmission Reports Found
            </Text>
          </HStack>
        </Flex>
      )}
      {transmissions.map(({ patient, ...transmission }, index) => (
        <>
          {index === pageNo && loadingBillableReports()}
          <Flex
            key={transmission.id}
            px="xl"
            py="2xl"
            borderBottomWidth="0.5px"
            _last={{ borderBottom: 'none' }}
            _hover={{ cursor: 'pointer' }}
            bg={
              transmission.id === selectedTransmissionId
                ? 'neutral.200'
                : 'initial'
            }
            boxShadow={
              transmission.id === selectedTransmissionId
                ? `inset 4px 0px ${primary500}`
                : 'none'
            }
            onClick={() => onSelect(transmission.id)}
            onMouseEnter={() => setSelectPatient(true)}
            onMouseLeave={() => setSelectPatient(false)}
          >
            <Flex width="md" mr="xl">
              {onCheckChange && (
                <Checkbox
                  isChecked={selectedTransmissionIds?.includes(transmission.id)}
                  onChange={e =>
                    handleCheckChange(transmission.id, e.target.checked)
                  }
                ></Checkbox>
              )}
            </Flex>
            <Flex flexDirection="column" flexGrow={1} position="relative">
              <HStack
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="6px 0px"
                gap="4px"
              >
                {(transmission.color === 'GREEN' ||
                  transmission.color === 'GRAY') &&
                  transmission.smartNarrativeStatus === 'True' && (
                    <Box
                      background="#EDF7FE"
                      borderRadius="4px"
                      padding="4px 6px 4px 6px"
                      width="123px"
                      height="20px"
                    >
                      <Text
                        width="111px"
                        height="12px"
                        fontFamily="Lato"
                        fontStyle="normal"
                        fontWeight={700}
                        fontSize="11px"
                        lineHeight="12px"
                        textAlign="center"
                        letterSpacing="0.06em"
                        textTransform="uppercase"
                        color="#0E6DAD"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="clip"
                      >
                        smart narrative
                      </Text>
                    </Box>
                  )}
                {transmission.flagHeartFailure && (
                  <Box
                    background="#EDF7FE"
                    borderRadius="4px"
                    padding="4px 6px 4px 6px"
                    width="110px"
                    height="20px"
                  >
                    <Text
                      width="95px"
                      height="12px"
                      fontFamily="Lato"
                      fontStyle="normal"
                      fontWeight={700}
                      fontSize="11px"
                      lineHeight="12px"
                      textAlign="center"
                      textTransform="uppercase"
                      color="#0E6DAD"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="clip"
                    >
                      Heart Failure
                    </Text>
                  </Box>
                )}
              </HStack>
              <HStack display={'inline-block'}>
                <Text
                  fontWeight="bold"
                  float={'left'}
                  style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}
                >
                  {`${patient?.familyName}, ${patient?.givenName}`}
                  {renderIcon(
                    fetchReportIsBillable(transmission.id)?.isBillable,
                    fetchReportIsBillable(transmission.id)?.isEriTracked,
                    fetchReportIsBillable(transmission.id)
                      ?.scheduleTransmissionType,
                  )}
                </Text>
              </HStack>
              <HStack spacing={'xs'}>
                <Text variant="secondary">
                  {transmission.deviceVendor?.vendorName}
                </Text>
                <Text variant="secondary">
                  {transmission.device?.deviceModelNumber}
                </Text>
              </HStack>
            </Flex>
            <Flex>
              {selectPatient && transmission.id === selectedTransmissionId ? (
                <Popover
                  returnFocusOnClose={false}
                  isOpen={isOpen}
                  onClose={close}
                  placement="top"
                  closeOnBlur={false}
                >
                  <PopoverTrigger>
                    <HStack>
                      <Icon
                        onClick={open}
                        background="#eff2f6"
                        padding="5px"
                        borderRadius={2}
                        fontSize={16}
                        color="#233349"
                        icon="close-circle"
                        boxSize="md"
                      />
                    </HStack>
                  </PopoverTrigger>
                  <PopoverContent
                    color="white"
                    bg="#213147e0"
                    borderColor="#213147"
                    padding="16px"
                  >
                    <PopoverArrow bg="#213147e0" />
                    <PopoverBody>
                      <HStack marginBottom="16px">
                        <Text color="white">
                          Are you sure you want to dismiss{' '}
                          {`${patient?.givenName} ${patient?.familyName}`} ?{' '}
                        </Text>
                      </HStack>
                      <Text color="white">This action cannot be undone.</Text>
                    </PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm" marginTop="15px">
                        <Button variant="plain" onClick={close}>
                          Cancel
                        </Button>
                        <Button
                          variant="danger"
                          colorScheme="red"
                          onClick={() => handleDismiss(transmission.id)}
                        >
                          Dismiss
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              ) : (
                ''
              )}
            </Flex>
          </Flex>
        </>
      ))}
      {isLoading && (
        <div
          style={{
            width: '100%',
            height: transmissions.length === 0 ? '80%' : '30%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
            alignItems: 'center',
          }}
        >
          <Spinner size={'xl'} color="primary.400" />
        </div>
      )}
    </AccordionPanel>
  )
}
