// This util file contains the base utils for the project.
export function sortArray<T>(inputArray: T[], property?: keyof T): T[] {
  inputArray.sort((a, b) => {
    // Extract and normalize property values if a property is specified
    const propA = String(property ? a[property] : a).toLowerCase()
    const propB = String(property ? b[property] : b).toLowerCase()

    // Check if the property values are numeric or strings
    if (!isNaN(Number(propA)) && !isNaN(Number(propB))) {
      // If both values are numeric, perform numeric comparison
      return Number(propA) - Number(propB)
    } else {
      // Otherwise, perform a case-insensitive alphabetical sort
      return propA.localeCompare(propB)
    }
  })

  return inputArray
}
