import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiReportTemplateControllerApplyTemplateRequest,
  queryClient,
} from '../../../lib/api'

export const useApplyReportTemplate = (toast: any) => {
  const Api = useApiContext()
  return useMutation(
    (
      reportTemplateApply: DefaultApiReportTemplateControllerApplyTemplateRequest,
    ) => Api.reportTemplateControllerApplyTemplate(reportTemplateApply),
    {
      onSuccess: ({ data }) => {
        queryClient
          .invalidateQueries('transmissionReport')
          .then(() => {
            toast(data.template)
          })
          .catch(() => {})
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
