import {
  useCreateTransmissionReportBilling,
  usePatchTransmissionReportBilling,
} from '../api'

export function useTransmissionReportBilling() {
  const { isLoading: isCreatingBilling, mutateAsync: createBilling } =
    useCreateTransmissionReportBilling()

  const { isLoading: isUpdatingBilling, mutateAsync: updateBilling } =
    usePatchTransmissionReportBilling()

  return {
    isLoading: (isCreatingBilling || isUpdatingBilling) ?? false,
    createBilling,
    updateBilling,
  }
}
