import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest,
  IsBillableTransmissionReports,
} from '../../../../lib/api'

export const useGetTransmissionReportsAreBillable = (
  params: DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest,
  options?: Pick<
    UseQueryOptions<IsBillableTransmissionReports[], Error>,
    'enabled' | 'onSuccess'
  >,
) => {
  const Api = useApiContext()
  return useQuery(
    ['transmissionReportsBillable', params.transmissionReportIds],
    async () => {
      const res =
        await Api.transmissionReportsControllerFetchIsBillableForReports(params)
      return res.data.isBillableData
    },
    {
      ...options,
    },
  )
}
