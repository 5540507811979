import { DeviceVendors, PatientOacEnum, Roles } from '../../lib/api'

export const PRACTITIONER_SIGNATURE_ROLES: Roles[] = [
  Roles.ExternalpractitionerAdmin,
  Roles.Externalpractitioner,
  Roles.Externalprovider,
]

export const SIGNALL_ROLES: Roles[] = [
  Roles.ExternalpractitionerAdmin,
  Roles.Externalpractitioner,
  Roles.Externalprovider,
]

export const HIDE_SIGNALL_ROLES: Roles[] = [
  Roles.InternalapiAccess,
  Roles.InternalsuperAdmin,
  Roles.Internaladmin,
  Roles.Internalclinical,
  Roles.InternalnonClinical,
  Roles.Externaladmin,
  Roles.ExternalnonPractitionerAdmin,
  Roles.ExternalnonPractitioner,
  Roles.ExternalnonProvider,
  Roles.Externalclerical,
  Roles.ExternalheartFailure,
]
export const REASONS_FOR_MONITORING_OPTIONS = [
  { label: 'AF Management', value: 'AF Management' },
  { label: 'Bradycardia', value: 'Bradycardia' },
  { label: 'Cardiac Arrest', value: 'Cardiac Arrest' },
  { label: 'Complete Heart Block', value: 'Complete Heart Block' },
  { label: 'Cryptogenic Stroke/TIA', value: 'Cryptogenic Stroke/TIA' },
  { label: 'Heart Failure Unspecified', value: 'Heart Failure Unspecified' },
  { label: 'High Grade AV Block', value: 'High Grade AV Block' },
  { label: 'Palpitations', value: 'Palpitations' },
  { label: 'Primary Prevention of SCD', value: 'Primary Prevention of SCD' },
  {
    label: 'Secondary Prevention of SCD',
    value: 'Secondary Prevention of SCD',
  },
  { label: 'Sick Sinus Syndrome', value: 'Sick Sinus Syndrome' },
  { label: 'Suspected AF', value: 'Suspected AF' },
  { label: 'Syncope', value: 'Syncope' },
  { label: 'Tachy-Brady Syndrome', value: 'Tachy-Brady Syndrome' },
  { label: 'Ventricular Tachycardia', value: 'Ventricular Tachycardia' },
]

export const OAC_OPTIONS = [
  { label: PatientOacEnum.NA, value: PatientOacEnum.NA },
  { label: PatientOacEnum.No, value: PatientOacEnum.No },
  { label: PatientOacEnum.NotTolerated, value: PatientOacEnum.NotTolerated },
  { label: PatientOacEnum.Yes, value: PatientOacEnum.Yes },
  {
    label: PatientOacEnum.PatientDeclined,
    value: PatientOacEnum.PatientDeclined,
  },
  {
    label: PatientOacEnum.HOLAAClosureDevice,
    value: PatientOacEnum.HOLAAClosureDevice,
  },
  {
    label: PatientOacEnum.Contraindicated,
    value: PatientOacEnum.Contraindicated,
  },
]

export const DEVICES_VENDOR_OPTIONS = [
  {
    label: 'Abbott',
    url: 'https://www.merlin.net',
    value: DeviceVendors.Abbott,
  },
  {
    label: 'BIOTRONIK',
    url: 'https://www.biotronik-homemonitoring.com/hmsc_guiWeb/user/site/SignIn.jsf',
    value: DeviceVendors.Biotronik,
  },
  {
    label: 'Boston Scientific',
    url: 'https://www.latitude.bostonscientific.com/',
    value: DeviceVendors.BostonScientific,
  },
  {
    label: 'Medtronic',
    url: 'https://clc.medtroniccarelink.net/Clinician/Login/login.aspx?ReturnUrl=%2fClinician%2fhome.aspx',
    value: DeviceVendors.Medtronic,
  },
]

export const VENDOR_DATA_OVERRIDE_KEYS = [
  'device.batteryVoltage',
  'device.deviceImplantDate',
  'device.batteryStatus',
  'device.batteryMeasurementTime',
  'device.batteryRemainingLongevity',
  'device.batteryPercentRemaining',
  'device.capacitorChargeTime',
  'device.vendorId',
  'egmImage',

  'deviceMeasurement.afBurden',
  'deviceMeasurement.apAPacePercent',
  'deviceMeasurement.rvpRvPacePercent',
  'deviceMeasurement.bvpPercent',
  'deviceMeasurement.lvpPercent',
  'deviceMeasurement.hvImpedance',

  'deviceMeasurement.symptomCounters',
  'deviceMeasurement.symptomTime',
  'deviceMeasurement.tachyCounters',
  'deviceMeasurement.tachyTime',
  'deviceMeasurement.pauseCounters',
  'deviceMeasurement.pauseTime',
  'deviceMeasurement.bradyCounters',
  'deviceMeasurement.bradyTime',
  'deviceMeasurement.atCounters',
  'deviceMeasurement.atTime',
  'deviceMeasurement.afCounters',
  'deviceMeasurement.afTime',
  'deviceMeasurement.atafCounters',
  'deviceMeasurement.atafTime',

  'deviceLeads.pulsewidth',
  'deviceLeads.sensing',
  'deviceLeads.impedance',
  'deviceLeads.threshold',
  'deviceLeads.leadModelNumber',
  'deviceLeads.vendorId',
  'vendor.vendorName',
]

export const Professional_OPTIONS_REMOTE = [
  {
    label: '93294',
    value: '93294',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis',
  },
  {
    label: '93295',
    value: '93295',
    description: 'Remote Professional ICD monitoring evaluation analysis',
  },
  {
    label: '93297',
    value: '93297',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298',
    value: '93298',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
  {
    label: '93297-26',
    value: '93297-26',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298-26',
    value: '93298-26',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
]

export const Professional_OPTIONS_IN_CLINIC = [
  {
    label: '93260',
    value: '93260',
    description:
      'In-office evaluation of programmable device with iterative adjustments.',
  },
  { label: '93261', value: '93261', description: '' },
  {
    label: '93279',
    value: '93279',
    description: 'In-office SR PM with programming changes',
  },
  {
    label: '93280',
    value: '93280',
    description: 'In-office DR PM with programming changes',
  },
  {
    label: '93281',
    value: '93281',
    description: 'In-office CRT-P with programming changes',
  },
  {
    label: '93282',
    value: '93282',
    description: 'In-office VR ICD with programming changes',
  },
  {
    label: '93283',
    value: '93283',
    description: 'In-office DR ICD with programming changes',
  },
  {
    label: '93284',
    value: '93284',
    description: 'In-office CRT-D with programming changes',
  },
  {
    label: '93285',
    value: '93285',
    description: 'In-office ILR with programming changes',
  },
  { label: '93286', value: '93286', description: '' },
  { label: '93287', value: '93287', description: '' },
  {
    label: '93288',
    value: '93288',
    description: 'In-office Any PM without programming changes',
  },
  {
    label: '93289',
    value: '93289',
    description: 'In-office Any ICD without programming changes',
  },
  { label: '93292', value: '93292', description: '' },
  { label: '93724', value: '93724', description: '' },
]

export const Professional_OPTIONS_MANUAL = [
  { label: '93260', value: '93260', description: '' },
  { label: '93261', value: '93261', description: '' },
  {
    label: '93279',
    value: '93279',
    description: 'In-office SR PM with programming changes',
  },
  {
    label: '93280',
    value: '93280',
    description: 'In-office DR PM with programming changes',
  },
  {
    label: '93281',
    value: '93281',
    description: 'In-office CRT-P with programming changes',
  },
  {
    label: '93282',
    value: '93282',
    description: 'In-office VR ICD with programming changes',
  },
  {
    label: '93283',
    value: '93283',
    description: 'In-office DR ICD with programming changes',
  },
  {
    label: '93284',
    value: '93284',
    description: 'In-office CRT-D with programming changes',
  },
  {
    label: '93285',
    value: '93285',
    description: 'In-office ILR with programming changes',
  },
  { label: '93286', value: '93286', description: '' },
  { label: '93287', value: '93287', description: '' },
  {
    label: '93288',
    value: '93288',
    description: 'In-office Any PM without programming changes',
  },
  {
    label: '93289',
    value: '93289',
    description: 'In-office Any ICD without programming changes',
  },
  { label: '93292', value: '93292', description: '' },
  {
    label: '93294',
    value: '93294',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis',
  },
  {
    label: '93295',
    value: '93295',
    description: 'Remote Professional ICD monitoring evaluation analysis',
  },
  {
    label: '93297',
    value: '93297',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298',
    value: '93298',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
  {
    label: '93297-26',
    value: '93297-26',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with professional modifier',
  },
  {
    label: '93298-26',
    value: '93298-26',
    description:
      'Remote Professional ILR monitoring evaluation analysis with professional modifier',
  },
]

export const TECHNICAL_OPTIONS = [
  { label: '-', value: '-', description: '' },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
  {
    label: '93297-TC',
    value: '93297-TC',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93298-TC',
    value: '93298-TC',
    description:
      'Remote Professional ILR monitoring evaluation analysis with Technical modifier',
  },
]

export const Technical_OPTIONS_ILR = [
  { label: '-', value: '-', description: '' },
  {
    label: '93298-TC',
    value: '93298-TC',
    description:
      'Remote Professional ILR monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
]

export const Technical_OPTIONS_Heart_Failure = [
  { label: '-', value: '-', description: '' },
  {
    label: '93297-TC',
    value: '93297-TC',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
]

export const diagnosis_OPTIONS = [
  { label: 'Z45.018', value: 'Z45.018' },
  { label: 'Z95.810', value: 'Z95.810' },
  { label: 'Z95.818', value: 'Z95.818' },
  {
    label: 'I44.2',
    value: 'I44.2',
    description: 'I44.2 - Atrioventricular block, complete',
  },
  {
    label: 'I44.0',
    value: 'I44.0',
    description: 'I44.0 - Atrioventricular block, first degree',
  },
  {
    label: 'I44.1',
    value: 'I44.1',
    description: 'I44.1 - Atrioventricular block, second degree',
  },

  {
    label: 'I48.4',
    value: 'I48.4',
    description: 'I48.4 - Atypical atrial flutter',
  },
  {
    label: 'R00.1',
    value: 'R00.1',
    description: 'R00.1 - Bradycardia, unspecified',
  },
  {
    label: 'I46.8',
    value: 'I46.8',
    description: 'I46.8 - Cardiac arrest due to other underlying condition',
  },
  {
    label: 'I46.2',
    value: 'I46.2',
    description: 'I46.2 - Cardiac arrest due to underlying cardiac condition',
  },
  {
    label: 'I42.7',
    value: 'I42.7',
    description: 'I42.7 - Cardiomyopathy due to drug and external agent',
  },
  {
    label: 'I43',
    value: 'I43',
    description: 'I43 - Cardiomyopathy in diseases classified elsewhere',
  },
  {
    label: 'G90.01',
    value: 'G90.01',
    description: 'G90.01 - Carotid sinus syncope',
  },
  {
    label: 'R07.9',
    value: 'R07.9',
    description: 'R07.9 - Chest pain, unspecified',
  },
  {
    label: 'I48.20',
    value: 'I48.20',
    description: 'I48.20 - Chronic atrial fibrillation, unspecified',
  },
  {
    label: 'I42.0',
    value: 'I42.0',
    description: 'I42.0 - Dilated cardiomyopathy',
  },
  {
    label: 'R42',
    value: 'R42',
    description: 'R42 - Dizziness and giddiness',
  },
  {
    label: 'I25.5',
    value: 'I25.5',
    description: 'I25.5 - Ischemic cardiomyopathy',
  },
  {
    label: 'I45.81',
    value: 'I45.81',
    description: 'I45.81 - Long QT syndrome',
  },
  {
    label: 'I48.11',
    value: 'I48.11',
    description: 'I48.11 - Longstanding persistent atrial fibrillation',
  },
  {
    label: 'I42.1',
    value: 'I42.1',
    description: 'I42.1 - Obstructive hypertrophic cardiomyopathy',
  },
  {
    label: 'I44.39',
    value: 'I44.39',
    description: 'I44.39 - Other atrioventricular block',
  },
  {
    label: 'I42.8',
    value: 'I42.8',
    description: 'I42.8 - Other cardiomyopathies',
  },
  {
    label: 'R07.89',
    value: 'R07.89',
    description: 'R07.89 - Other chest pain',
  },
  {
    label: 'I24.8',
    value: 'I24.8',
    description: 'I24.8 - Other forms of acute ischemic heart disease',
  },
  {
    label: 'I42.2',
    value: 'I42.2',
    description: 'I42.2 - Other hypertrophic cardiomyopathy',
  },
  {
    label: 'I48.19',
    value: 'I48.19',
    description: 'I48.19 - Other persistent atrial fibrillation',
  },
  {
    label: 'I45.19',
    value: 'I45.19',
    description: 'I45.19 - Other right bundle-branch block',
  },
  {
    label: 'I49.8',
    value: 'I49.8',
    description: 'I49.8 - Other specified cardiac arrhythmias',
  },
  {
    label: 'I45.89',
    value: 'I45.89',
    description: 'I45.89 - Other specified conduction disorders',
  },
  {
    label: 'G45.8',
    value: 'G45.8',
    description:
      'G45.8 - Other transient cerebral ischemic attacks and related syndromes',
  },
  {
    label: 'I47.29',
    value: 'I47.29',
    description: 'I47.29 - Other ventricular tachycardia',
  },
  {
    label: 'R00.2',
    value: 'R00.2',
    description: 'R00.2 - Palpitations',
  },
  {
    label: 'I48.0',
    value: 'I48.0',
    description: 'I48.0 - Paroxysmal atrial fibrillation',
  },
  {
    label: 'I48.21',
    value: 'I48.21',
    description: 'I48.21 - Permanent atrial fibrillation',
  },
  {
    label: 'Z86.73',
    value: 'Z86.73',
    description:
      'Z86.73 - Personal history of transient ischemic attack (TIA), and cerebral infarction without residual deficits',
  },
  {
    label: 'I49.5',
    value: 'I49.5',
    description: 'I49.5 - Sick sinus syndrome',
  },
  {
    label: 'I47.1',
    value: 'I47.1',
    description: 'I47.1 - Supraventricular tachycardia',
  },
  {
    label: 'R55',
    value: 'R55',
    description: 'R55 - Syncope and collapse',
  },
  {
    label: 'R00.0',
    value: 'R00.0',
    description: 'R00.0 - Tachycardia, unspecified',
  },
  {
    label: 'I47.21',
    value: 'I47.21',
    description: 'I47.21 - Torsades de pointes',
  },
  {
    label: 'I48.3',
    value: 'I48.3',
    description: 'I48.3 - Typical atrial flutter',
  },
  {
    label: 'I48.91',
    value: 'I48.91',
    description: 'I48.91 - Unspecified atrial fibrillation',
  },
  {
    label: 'I48.92',
    value: 'I48.92',
    description: 'I48.92 - Unspecified atrial flutter',
  },
  {
    label: 'I49.01',
    value: 'I49.01',
    description: 'I49.01 - Ventricular fibrillation',
  },
  {
    label: 'I49.02',
    value: 'I49.02',
    description: 'I49.02 - Ventricular flutter',
  },
  {
    label: 'I49.3',
    value: 'I49.3',
    description: 'I49.3 - Ventricular premature depolarization',
  },
  {
    label: 'I42.6',
    value: 'I42.6',
    description: 'I42.6 - Alcoholic cardiomyopathy',
  },
  {
    label: 'G45.3',
    value: 'G45.3',
    description: 'G45.3 - Amaurosis fugax',
  },
  {
    label: 'I25.3',
    value: 'I25.3',
    description: 'I25.3 - Aneurysm of heart',
  },
  {
    label: 'I20.1',
    value: 'I20.1',
    description: 'I20.1 - Angina pectoris with documented spasm',
  },
  {
    label: 'I25.721',
    value: 'I25.721',
    description:
      'I25.721 - Atherosclerosis of autologous artery coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.728',
    value: 'I25.728',
    description:
      'I25.728 - Atherosclerosis of autologous artery coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.722',
    value: 'I25.722',
    description:
      'I25.722 - Atherosclerosis of autologous artery coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.720',
    value: 'I25.720',
    description:
      'I25.720 - Atherosclerosis of autologous artery coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.711',
    value: 'I25.711',
    description:
      'I25.711 - Atherosclerosis of autologous vein coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.718',
    value: 'I25.718',
    description:
      'I25.718 - Atherosclerosis of autologous vein coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.712',
    value: 'I25.712',
    description:
      'I25.712 - Atherosclerosis of autologous vein coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.710',
    value: 'I25.710',
    description:
      'I25.710 - Atherosclerosis of autologous vein coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.761',
    value: 'I25.761',
    description:
      'I25.761 - Atherosclerosis of bypass graft of coronary artery of transplanted heart with angina pectoris with documented spasm',
  },
  {
    label: 'I25.768',
    value: 'I25.768',
    description:
      'I25.768 - Atherosclerosis of bypass graft of coronary artery of transplanted heart with other forms of angina pectoris',
  },
  {
    label: 'I25.762',
    value: 'I25.762',
    description:
      'I25.762 - Atherosclerosis of bypass graft of coronary artery of transplanted heart with refractory angina pectoris',
  },
  {
    label: 'I25.760',
    value: 'I25.760',
    description:
      'I25.760 - Atherosclerosis of bypass graft of coronary artery of transplanted heart with unstable angina',
  },
  {
    label: 'I25.812',
    value: 'I25.812',
    description:
      'I25.812 - Atherosclerosis of bypass graft of coronary artery of transplanted heart without angina pectoris',
  },
  {
    label: 'I25.810',
    value: 'I25.810',
    description:
      'I25.810 - Atherosclerosis of coronary artery bypass graft(s) without angina pectoris',
  },
  {
    label: 'I25.751',
    value: 'I25.751',
    description:
      'I25.751 - Atherosclerosis of native coronary artery of transplanted heart with angina pectoris with documented spasm',
  },
  {
    label: 'I25.750',
    value: 'I25.750',
    description:
      'I25.750 - Atherosclerosis of native coronary artery of transplanted heart with unstable angina',
  },
  {
    label: 'I25.811',
    value: 'I25.811',
    description:
      'I25.811 - Atherosclerosis of native coronary artery of transplanted heart without angina pectoris',
  },
  {
    label: 'I25.731',
    value: 'I25.731',
    description:
      'I25.731 - Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.738',
    value: 'I25.738',
    description:
      'I25.738 - Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.732',
    value: 'I25.732',
    description:
      'I25.732 - Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.730',
    value: 'I25.730',
    description:
      'I25.730 - Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.791',
    value: 'I25.791',
    description:
      'I25.791 - Atherosclerosis of other coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.798',
    value: 'I25.798',
    description:
      'I25.798 - Atherosclerosis of other coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.792',
    value: 'I25.792',
    description:
      'I25.792 - Atherosclerosis of other coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.790',
    value: 'I25.790',
    description:
      'I25.790 - Atherosclerosis of other coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.111',
    value: 'I25.111',
    description:
      'I25.111 - Atherosclerotic heart disease of native coronary artery with angina pectoris with documented spasm',
  },
  {
    label: 'I25.118',
    value: 'I25.118',
    description:
      'I25.118 - Atherosclerotic heart disease of native coronary artery with other forms of angina pectoris',
  },
  {
    label: 'I25.110',
    value: 'I25.110',
    description:
      'I25.110 - Atherosclerotic heart disease of native coronary artery with unstable angina pectoris',
  },
  {
    label: 'I25.10',
    value: 'I25.10',
    description:
      'I25.10 - Atherosclerotic heart disease of native coronary artery without angina pectoris',
  },
  {
    label: 'I49.1',
    value: 'I49.1',
    description: 'I49.1 - Atrial premature depolarization',
  },
  {
    label: 'I45.2',
    value: 'I45.2',
    description: 'I45.2 - Bifascicular block',
  },
  {
    label: 'I42.9',
    value: 'I42.9',
    description: 'I42.9 - Cardiomyopathy, unspecified',
  },
  {
    label: 'I63.9',
    value: 'I63.9',
    description: 'I63.9 - Cerebral infarction, unspecified',
  },
  {
    label: 'I67.9',
    value: 'I67.9',
    description: 'I67.9 - Cerebrovascular disease, unspecified',
  },
  {
    label: 'R07.1',
    value: 'R07.1',
    description: 'R07.1 - Chest pain on breathing',
  },
  {
    label: 'I50.42',
    value: 'I50.42',
    description: 'I50.42 - Chronic combined systolic/diastolic HF (congestive)',
  },
  {
    label: 'I50.22',
    value: 'I50.22',
    description: 'I50.22 - Chronic systolic (Congestive) HF',
  },
  {
    label: 'I25.82',
    value: 'I25.82',
    description: 'I25.82 - Chronic total occlusion of coronary artery',
  },
  {
    label: 'I25.41',
    value: 'I25.41',
    description: 'I25.41 - Coronary artery aneurysm',
  },
  {
    label: 'I25.84',
    value: 'I25.84',
    description:
      'I25.84 - Coronary atherosclerosis due to calcified coronary lesion',
  },
  {
    label: 'I25.83',
    value: 'I25.83',
    description: 'I25.83 - Coronary atherosclerosis due to lipid-rich plaque',
  },
  {
    label: 'R06.00',
    value: 'R06.00',
    description: 'R06.00 - Dyspnea, unspecified',
  },
  {
    label: 'I42.4',
    value: 'I42.4',
    description: 'I42.4 - Endocardial fibroelastosis',
  },
  {
    label: 'I42.3',
    value: 'I42.3',
    description: 'I42.3 - Endomyocardial (eosinophilic) disease',
  },
  {
    label: 'R10.13',
    value: 'R10.13',
    description: 'R10.13 - Epigastric pain',
  },
  {
    label: 'I49.2',
    value: 'I49.2',
    description: 'I49.2 - Junctional premature depolarization',
  },
  {
    label: 'I44.4',
    value: 'I44.4',
    description: 'I44.4 - Left anterior fascicular block',
  },
  {
    label: 'I44.5',
    value: 'I44.5',
    description: 'I44.5 - Left posterior fascicular block',
  },
  {
    label: 'I21.A1',
    value: 'I21.A1',
    description: 'I21.A1 - Myocardial infarction type 2',
  },
  {
    label: 'I5A',
    value: 'I5A',
    description: 'I5A - Non-ischemic myocardial injury (non-traumatic)',
  },
  {
    label: 'I21.4',
    value: 'I21.4',
    description: 'I21.4 - Non-ST elevation (NSTEMI) myocardial infarction',
  },
  {
    label: 'I25.2',
    value: 'I25.2',
    description: 'I25.2 - Old myocardial infarction',
  },
  {
    label: 'R06.01',
    value: 'R06.01',
    description: 'R06.01 - Orthopnea',
  },
  {
    label: 'R00.8',
    value: 'R00.8',
    description: 'R00.8 - Other abnormalities of heart beat',
  },
  {
    label: 'I44.69',
    value: 'I44.69',
    description: 'I44.69 - Other fascicular block',
  },
  {
    label: 'I20.8',
    value: 'I20.8',
    description: 'I20.8 - Other forms of angina pectoris',
  },
  {
    label: 'I25.89',
    value: 'I25.89',
    description: 'I25.89 - Other forms of chronic ischemic heart disease',
  },
  {
    label: 'R06.09',
    value: 'R06.09',
    description: 'R06.09 - Other forms of dyspnea',
  },
  {
    label: 'I21.A9',
    value: 'I21.A9',
    description: 'I21.A9 - Other myocardial infarction type',
  },
  {
    label: 'I97.190',
    value: 'I97.190',
    description:
      'I97.190 - Other postprocedural cardiac functional disturbances following cardiac surgery',
  },
  {
    label: 'I97.191',
    value: 'I97.191',
    description:
      'I97.191 - Other postprocedural cardiac functional disturbances following other surgery',
  },
  {
    label: 'I49.49',
    value: 'I49.49',
    description: 'I49.49 - Other premature depolarization',
  },
  {
    label: 'I42.5',
    value: 'I42.5',
    description: 'I42.5 - Other restrictive cardiomyopathy',
  },
  {
    label: 'I45.5',
    value: 'I45.5',
    description: 'I45.5 - Other specified heart block',
  },
  {
    label: 'Z86.74',
    value: 'Z86.74',
    description: 'Z86.74 - Personal history of sudden cardiac arrest',
  },
  {
    label: 'I23.7',
    value: 'I23.7',
    description: 'I23.7 - Postinfarction angina',
  },
  {
    label: 'I97.120',
    value: 'I97.120',
    description:
      'I97.120 - Postprocedural cardiac arrest following cardiac surgery',
  },
  {
    label: 'I97.121',
    value: 'I97.121',
    description:
      'I97.121 - Postprocedural cardiac arrest following other surgery',
  },
  {
    label: 'I45.6',
    value: 'I45.6',
    description: 'I45.6 - Pre-excitation syndrome',
  },
  {
    label: 'R07.2',
    value: 'R07.2',
    description: 'R07.2 - Precordial pain',
  },
  {
    label: 'I47.0',
    value: 'I47.0',
    description: 'I47.0 - Re-entry ventricular arrhythmia',
  },
  {
    label: 'I45.0',
    value: 'I45.0',
    description: 'I45.0 - Right fascicular block',
  },
  {
    label: 'R06.02',
    value: 'R06.02',
    description: 'R06.02 - Shortness of breath',
  },
  {
    label: 'I25.6',
    value: 'I25.6',
    description: 'I25.6 - Silent myocardial ischemia',
  },
  {
    label: 'I21.02',
    value: 'I21.02',
    description:
      'I21.02 - ST elevation (STEMI) myocardial infarction involving left anterior descending coronary artery',
  },
  {
    label: 'I21.21',
    value: 'I21.21',
    description:
      'I21.21 - ST elevation (STEMI) myocardial infarction involving left circumflex coronary artery',
  },
  {
    label: 'I21.01',
    value: 'I21.01',
    description:
      'I21.01 - ST elevation (STEMI) myocardial infarction involving left main coronary artery',
  },
  {
    label: 'I21.09',
    value: 'I21.09',
    description:
      'I21.09 - ST elevation (STEMI) myocardial infarction involving other coronary artery of anterior wall',
  },
  {
    label: 'I21.19',
    value: 'I21.19',
    description:
      'I21.19 - ST elevation (STEMI) myocardial infarction involving other coronary artery of inferior wall',
  },
  {
    label: 'I21.29',
    value: 'I21.29',
    description:
      'I21.29 - ST elevation (STEMI) myocardial infarction involving other sites',
  },
  {
    label: 'I21.11',
    value: 'I21.11',
    description:
      'I21.11 - ST elevation (STEMI) myocardial infarction involving right coronary artery',
  },
  {
    label: 'I22.2',
    value: 'I22.2',
    description:
      'I22.2 - Subsequent non-ST elevation (NSTEMI) myocardial infarction',
  },
  {
    label: 'I22.0',
    value: 'I22.0',
    description:
      'I22.0 - Subsequent ST elevation (STEMI) myocardial infarction of anterior wall',
  },
  {
    label: 'I22.1',
    value: 'I22.1',
    description:
      'I22.1 - Subsequent ST elevation (STEMI) myocardial infarction of inferior wall',
  },
  {
    label: 'I22.8',
    value: 'I22.8',
    description:
      'I22.8 - Subsequent ST elevation (STEMI) myocardial infarction of other sites',
  },
  {
    label: 'R40.4',
    value: 'R40.4',
    description: 'R40.4 - Transient alteration of awareness',
  },
  {
    label: 'G45.9',
    value: 'G45.9',
    description: 'G45.9 - Transient cerebral ischemic attack, unspecified',
  },
  {
    label: 'I45.3',
    value: 'I45.3',
    description: 'I45.3 - Trifascicular block',
  },
  {
    label: 'I20.0',
    value: 'I20.0',
    description: 'I20.0 - Unstable angina',
  },
  {
    label: 'I44.60',
    value: 'I44.60',
    description: 'I44.60 - Unspecified fascicular block',
  },
  {
    label: 'I44.7',
    value: 'I44.7',
    description: 'I44.7 - Left bundle-branch block, unspecified',
  },
  {
    label: 'I45.10',
    value: 'I45.10',
    description: 'I45.10 - Unspecified right bundle-branch block',
  },
  {
    label: 'I45.9',
    value: 'I45.9',
    description: 'I45.9 - Conduction disorder, unspecified',
  },
  {
    label: 'I46.9',
    value: 'I46.9',
    description: 'I46.9 - Cardiac arrest, cause unspecified',
  },
  {
    label: 'I47.11',
    value: 'I47.11',
    description: 'I47.11 - Inappropriate sinus tachycardia, so stated',
  },
  {
    label: 'I47.19',
    value: 'I47.19',
    description: 'I47.19 - Other supraventricular tachycardia',
  },
  {
    label: 'I47.9',
    value: 'I47.9',
    description: 'I47.9 - Paroxysmal tachycardia, unspecified',
  },
  {
    label: 'I50.1',
    value: 'I50.1',
    description: 'I50.1 - Left ventricular failure, unspecified',
  },
  {
    label: 'I50.20',
    value: 'I50.20',
    description: 'I50.20 - Unspecified systolic (congestive) heart failure',
  },
  {
    label: 'I50.21',
    value: 'I50.21',
    description: 'I50.21 - Acute systolic (congestive) heart failure',
  },
  {
    label: 'I50.23',
    value: 'I50.23',
    description:
      'I50.23 - Acute on chronic systolic (congestive) heart failure',
  },
  {
    label: 'I50.41',
    value: 'I50.41',
    description:
      'I50.41 - Acute combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    label: 'I50.43',
    value: 'I50.43',
    description:
      'I50.43 - Acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    label: 'I50.810',
    value: 'I50.810',
    description: 'I50.810 - Right heart failure, unspecified',
  },
  {
    label: 'I50.811',
    value: 'I50.811',
    description: 'I50.811 - Acute right heart failure',
  },
  {
    label: 'I50.812',
    value: 'I50.812',
    description: 'I50.812 - Chronic right heart failure',
  },
  {
    label: 'I50.813',
    value: 'I50.813',
    description: 'I50.813 - Acute on chronic right heart failure',
  },
  {
    label: 'I50.814',
    value: 'I50.814',
    description: 'I50.814 - Right heart failure due to left heart failure',
  },
  {
    label: 'I50.82',
    value: 'I50.82',
    description: 'I50.82 - Biventricular heart failure',
  },
  {
    label: 'I50.83',
    value: 'I50.83',
    description: 'I50.83 - High output heart failure',
  },
  {
    label: 'I50.84',
    value: 'I50.84',
    description: 'I50.84 - End stage heart failure',
  },
  {
    label: 'I50.89',
    value: 'I50.89',
    description: 'I50.89 - Other heart failure',
  },
  {
    label: 'I50.9',
    value: 'I50.9',
    description: 'I50.9 - Heart failure, unspecified',
  },
  {
    label: 'Z45.010',
    value: 'Z45.010',
    description:
      'Z45.010 - Encounter for checking and testing of cardiac pacemaker pulse generator [battery]',
  },
  {
    label: 'Z45.018',
    value: 'Z45.018',
    description:
      'Z45.018 - Encounter for adjustment and management of other part of cardiac pacemaker',
  },
  {
    label: 'Z45.09',
    value: 'Z45.09',
    description:
      'Z45.09 - Encounter for adjustment and management of other cardiac device',
  },
  {
    label: 'Z95.0',
    value: 'Z95.0',
    description: 'Z95.0 - Presence of cardiac pacemaker',
  },
  {
    label: 'Z95.818',
    value: 'Z95.818',
    description: 'Z95.818 - Presence of other cardiac implants and grafts',
  },
  {
    label: 'Z95.9',
    value: 'Z95.9',
    description:
      'Z95.9 - Presence of cardiac and vascular implant and graft, unspecified',
  },
]

export const diagnosisDefaultValueByDeviceType = (deviceType: any): string => {
  if (deviceType === '' || deviceType === undefined) {
    return ''
  }
  if (deviceType === 'CRT-P' || deviceType === 'PM') {
    return 'Z45.018'
  } else if (deviceType === 'ILR') {
    return 'Z95.818'
  } else if (deviceType === 'ICD' || deviceType === 'CRT-D') {
    return 'Z95.810'
  } else return ''
}

export enum AppDeterminedBillableReasons {
  CALCULATED = 'calculated',
  SIXTY_TWENTY_RULE = '60/20',
}
